<template>
  <Breadcrumbs main="Bandeja" title="Historial" />
  <div class="container-fluid">
    <div class="row project-cards">
      <TopMenu />
    </div>
  </div>
</template>
<script>
import TopMenu from "./components/TopMenu.vue";
export default {
  name: "place",
  data() {},
  components: {
    TopMenu,
  },
};
</script>
