<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">ID</th>
              <th scope="col" class="text-center">Evento</th>
              <th scope="col" class="text-center">Detalle</th>
              <th scope="col" class="text-center">Mínimo/Máximo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ item.title }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.minimum }}/{{ item.maximum }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="props.list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps } from "vue";
import Pagination from "@/pages/components/Pagination.vue";

const props = defineProps({ list: Array });

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return props.list.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
