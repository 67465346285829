<template>
  <div class="col-md-12 project-list">
    <div class="card">
      <div class="row">
        <div class="col-md-6 d-flex"></div>
        <div class="col-md-6">
          <div class="form-group mb-0 me-0"></div>
          <ModalCreate @reload-list="reloadList" :tournamentId="tournamentId" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import ModalCreate from "./ModalCreate.vue";
import { defineEmits, defineProps } from "vue";
const emits = defineEmits(["reload-list"]);
const props = defineProps({ tournamentId: Number });
function reloadList() {
  emits("reload-list");
}
</script>
