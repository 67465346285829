<template>
  <li class="nav-item">
    <div style="padding: 30px 20px">
      <select class="form-select" @change="selectSchool()" v-model="selectItem">
        <option value="0" disabled>Seleccione Institución</option>
        <option
          v-for="item in list"
          :value="item.institution.id"
          :key="item.institution.id"
        >
          {{ item.institution.institucioneducativa }}
        </option>
      </select>
      <button class="btn btn-primary col-12" @click="downloadReport">
        Reporte Inscritos
      </button>
    </div>
  </li>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits } from "vue";
const props = defineProps({ list: Array, selected: Number });
const list = computed(() => {
  return props.list;
});

const emit = defineEmits(["selectSchool", "getReport"]);

let selectItem = ref(props.selected);

function selectSchool() {
  const item = props.list.find(
    (inst) => inst.institution.id === selectItem.value
  );
  emit("selectSchool", item);
}

function downloadReport() {
  emit("getReport");
}
</script>
