<template>
  <div class="card mb-0">
    <div class="card-header d-flex">
      <h5>Estudiantes</h5>

      <template v-if="system != 'sucursales'">
        <div class="text-center info-text">
          <p>{{ infoCourse?.level }}</p>
          <p>{{ infoCourse?.course }} - {{ infoCourse?.parallel }}</p>
        </div>
      </template>
      <template v-else>
        <div class="text-center info-text">
          <p style="font-size: 12px; font-weight: bold">
            {{ turnoDicc(institution.turno_tipo_id) }}
          </p>
          <p style="font-size: 13px">{{ institution.especialidad }}</p>
          <p style="font-size: 13px; font-weight: bold">
            {{ institution.acreditacion }}
          </p>
          <p>
            {{ parallelDicc(institution.paralelo_tipo_id) }}
          </p>
        </div>
      </template>

      <span class="f-14 pull-right mt-0">
        <!-- <div v-if="!statusEnrollment">
          <button
            class="btn btn-primary"
            @click="saveInscription"
            :disabled="statusEnrollment"
          >
            Guardar
          </button>
          <button
            class="btn btn-success"
            @click="consolidado"
            :disabled="statusEnrollment"
          >
            Inscribir
          </button>
        </div> -->
        <!-- <div v-else> -->
        <h6 class="text-success">Inscripción Cerrada</h6>
        <!-- </div> -->
      </span>
    </div>
    <div class="card-body">
      <Students
        :students="students"
        @toggleParticipant="refreshParticipantList"
        :participantSelected="participantSelected"
        :statusEnrollment="statusEnrollment"
      />
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  computed,
  inject,
  ref,
  onMounted,
  defineEmits,
} from "vue";
import Students from "./Students.vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";

const swal = inject("$swal");
const emit = defineEmits(["changeStatusEnrollment"]);

const props = defineProps({
  students: Array,
  institution: Object,
  infoCourse: Object,
  participantList: Array,
  participantSelected: Array,
  statusEnrollment: Boolean,
  system: String,
  departamentSelect: Number,
  systemSelect: Number,
});

const infoCourse = computed(() => {
  return props.infoCourse;
});

const students = computed(() => {
  return props.students;
});

const institution = computed(() => {
  return props.institution;
});
async function saveInscription() {
  console.log("participant", participantList.value);
  console.log("institution", institution.value);
  const request = {
    participants: participantList.value,
    institution_id: institution.value.institucioneducativa_id,
    level: institution.value.nivel_tipo_id,
    grade: institution.value.grado_tipo_id,
    parallel: institution.value.paralelo_tipo_id,
    course: institution.value.id,
    departament_id: props.departamentSelect,
    system_id: props.systemSelect,
    phase: 1,
    userId: localStorage.getItem("userId"),
  };
  console.log("request", request);
  if (!participantList.value.length) {
    toast.error(
      "Debe seleccionar al menos un estudiante para guardar la inscripción!"
    );
    return;
  }
  await axios
    .post("/enrollment-list", request)
    .then((response) => {
      toast.success("Registros Salvados!");
    })
    .catch(function (error) {
      toast.error("Registro fallido!");
    });
}
async function consolidado() {
  const request = {
    institution_id: institution.value.institucioneducativa_id,
    course: institution.value.id,
    phase: 1,
  };
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Una vez inscrito no se podrá modificar la lista de inscripciones",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await saveInscription();
        axios
          .post("/enrollment-list/close-enrollment", request)
          .then((response) => {
            toast.success("Inscripción exitosa!!!");
            emit("changeStatusEnrollment", true);
          })
          .catch(function (error) {
            toast.error("Inscripción fallida!!!");
          });
      }
    });
}

const participantList = computed(() => {
  return props.participantList;
});

const turnoDicc = (id) => {
  console.log("paralelo dictionay", id);
  const turnos = [
    { id: 0, turno: "SIN DEFINIR" },
    { id: 1, turno: "MAÑANA" },
    { id: 2, turno: "TARDE" },
    { id: 4, turno: "NOCHE" },
    { id: 8, turno: "MAÑANA TARDE" },
    { id: 9, turno: "TARDE NOCHE" },
    { id: 10, turno: "MAÑANA, TARDE Y NOCHE" },
    { id: 11, turno: "MAÑANA-NOCHE" },
  ];
  const item = turnos.find((item) => item.id == id);
  return item?.turno;
};

const parallelDicc = (id) => {
  console.log("paralelo dictionay", id);
  const item = listParallel.value.find((item) => item.id == id);
  return item?.paralelo;
};

const listParallel = ref([]);
function loadParallels() {
  axios
    .get("/institution/list/parallels")
    .then((response) => {
      if (response.data) {
        listParallel.value = response.data;
        console.log("parallels", listParallel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function refreshParticipantList(participant) {
  const removeItemIndex = participantList.value.indexOf(participant);
  const exist = participantList.value.find((item) => item === participant);
  if (exist) {
    participantList.value.splice(removeItemIndex, 1);
  } else {
    participantList.value.push(participant);
  }
  console.log("list", participantList.value);
}

onMounted(() => {
  loadParallels();
});
</script>
