<template>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Roles</h5>
        <button
          class="btn-close"
          type="button"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="card-body row switch-showcase">
          <div class="col-sm-12">
            <div class="media">
              <label class="col-form-label m-r-10">Administrador</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>

            <div class="media">
              <label class="col-form-label m-r-10">Comunicación</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>

            <div class="media">
              <label class="col-form-label m-r-10">Solicitante</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
            <div class="media">
              <label class="col-form-label m-r-10">Publico General</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
            <div class="media">
              <label class="col-form-label m-r-10">Estudiante</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
            <div class="media">
              <label class="col-form-label m-r-10">Maestros</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
            <div class="media">
              <label class="col-form-label m-r-10">Directores</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
            <div class="media">
              <label class="col-form-label m-r-10">Root</label>
              <div class="media-body text-end icon-state switch-outline">
                <label class="switch">
                  <input type="checkbox" /><span
                    class="switch-state bg-primary"
                  ></span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <script>
export default {
  name: "ModalDetails",
  props:
};
</script> -->
