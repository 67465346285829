<template>
  <div class="col-md-12 project-list">
    <div class="card">
      <div class="row">
        <div class="col-md-6 d-flex">
          <ul
            class="nav nav-tabs border-tab"
            id="top-tab"
            role="tablist"
            v-for="(item, index) in tab"
            :key="index"
          >
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: item.active }"
                id="top-home-tab"
                data-bs-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="top-home"
                :aria-selected="item.active ? 'true' : 'false'"
                @click.prevent="active(item)"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-6">
          <div class="form-group mb-0 me-0"></div>
          <button
            class="btn btn-primary"
            type="button"
            @click="createModal.show()"
          >
            Crear Evento
          </button>

          <div
            class="modal fade"
            ref="eventModal"
            tabindex="-1"
            role="dialog"
            aria-hidden="true"
          >
            <div
              class="modal-dialog modal-xl modal-dialog-centered"
              role="document"
            >
              <div class="modal-content">
                <div class="modal-body">
                  <BaseForm @reloadEvents="refreshList" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div class="tab-content" id="top-tabContent">
          <div
            class="tab-pane fade"
            :class="{ 'active show': item.active }"
            :id="item.id"
            role="tabpanel"
            :aria-labelledby="item.label"
            v-for="(item, index) in tab"
            :key="index"
          >
            <component
              :is="item.type"
              :list="listEvents"
              @reloadList="loadItems"
            ></component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "@/includes/connection";
import { ref, onMounted } from "vue";
import allPage from "../projectlist/allPage.vue";
import doingPage from "../projectlist/doingPage.vue";
import donePage from "../projectlist/donePage.vue";
import activePage from "../projectlist/activePage.vue";
import { Modal } from "bootstrap";
import BaseForm from "@/pages/eventos/components/baseForm.vue";

let tab = ref([
  {
    type: doingPage,
    name: "Creados",
    active: true,
    icon: "info",
    id: "top-profile",
    label: "profile-top-tab",
  },
  {
    type: activePage,
    name: "Activos",
    active: false,
    icon: "info",
    id: "top-profile",
    label: "profile-top-tab",
  },
  {
    type: donePage,
    name: "Concluidos",
    active: false,
    icon: "check-circle",
    id: "top-contact",
    label: "contact-top-tab",
  },
  {
    type: allPage,
    name: "Todos",
    active: false,
    icon: "target",
    id: "top-home",
    id1: "top-tabContent",
    label: "top-home-tab",
  },
]);

function active(item) {
  console.log("case", item);
  switch (item.name) {
    case "Todos":
      listEvents.value = allEvents.value.filter((item) => {
        return item;
      });
      break;
    case "Creados":
      listEvents.value = allEvents.value.filter((item) => {
        return item.state == 1;
      });
      break;
    case "Activos":
      listEvents.value = allEvents.value.filter((item) => {
        return item.state == 2;
      });
      break;
    case "Concluidos":
      listEvents.value = allEvents.value.filter((item) => {
        return item.state == 3;
      });
      break;
  }
  if (!item.active) {
    tab.value.forEach((a) => {
      a.active = false;
    });
  }
  item.active = !item.active;
}

const eventModal = ref(null);
let createModal = null;
const allEvents = ref([]);
const listEvents = ref([]);

onMounted(() => {
  createModal = new Modal(eventModal.value);
  loadItems();
});

function refreshList() {
  createModal.hide();
  loadItems();
}

function loadItems() {
  console.log("loadItems method ..");
  axios
    .get("/event/all")
    .then((response) => {
      if (response.data) {
        allEvents.value = response.data;
        listEvents.value = response.data;
      }
      console.log("load all ", allEvents.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
