<template>
  <div
    id="addModal"
    class="modal fade bd-example-modal-xl"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 style="font-size: 18px !important">Categorías Habilitadas</h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-5">
              <div>
                <b>Estudiante: </b>{{ student.first_last }}
                {{ student.second_last }} {{ student.name }}
              </div>
            </div>
            <div class="col-7">
              <div class="input-group mb-3">
                <span class="input-group-text"> Categoría </span>
                <select
                  class="form-control"
                  id="categorySelect"
                  v-model="category"
                  :disabled="enrolled"
                  @click="changeModeCategory"
                >
                  <option
                    v-for="category in filteredCategories"
                    :key="category.id"
                    :value="category.name"
                  >
                    {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="addNewTutor"
            v-if="!enrolled"
          >
            Registrar
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeModal"
            v-if="enrolled"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  getCurrentInstance,
  onMounted,
} from "vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";
import Category from "@/pages/manager/category/Category.vue";

const { proxy } = getCurrentInstance();
const formattedDate = computed(() =>
  proxy.$formatDate(props.tutor[0].fecha_nacimiento_tut)
);

const props = defineProps({
  student: Object,
  enrolled: Boolean,
  codeSie: Number,
  codeLevel: Number,
  codeCourse: Number,
  codeGrade: Number,
});

const myLevel = computed(() => {
  return props.codeLevel;
});

const myGrade = computed(() => {
  return props.codeGrade;
});

const myStudent = computed(() => {
  return props.student;
});

const changeModeCategory = () => {
  console.log("codelevel", props.codeCourse);
  console.log("codelevel2", myLevel.value);
};

// ---section category
const categories = ref([
  {
    id: 1,
    name: "Informática - UNICODEMY A - MUJERES",
    rules: [
      { system: 12, grades: [6], genre: [2] },
      { system: 13, grades: [1, 2], genre: [2] },
    ],
  },
  {
    id: 2,
    name: "Informática - UNICODEMY B - MUJERES",
    rules: [{ system: 13, grades: [3, 4, 5, 6], genre: [2] }],
  },
  {
    id: 3,
    name: "Informática - NIVEL 0 - JASPER",
    rules: [
      { system: 12, grades: [6], genre: [1, 2] },
      { system: 13, grades: [1], genre: [1, 2] },
    ],
  },
  {
    id: 4,
    name: "Informática - NIVEL 0 - UBERFLIP",
    rules: [
      { system: 12, grades: [6], genre: [1, 2] },
      { system: 13, grades: [1, 2], genre: [1, 2] },
    ],
  },
  {
    id: 5,
    name: "Informática - NIVEL 1 - FIREWALL 1",
    rules: [
      { system: 13, grades: [1, 2], genre: [1, 2] },
      { system: 12, grades: [6], genre: [1, 2] },
    ],
  },
  {
    id: 6,
    name: "Informática - NIVEL 1 - FIREWALL 2",
    rules: [{ system: 13, grades: [3, 4, 5, 6], genre: [1, 2] }],
  },
  {
    id: 7,
    name: "Informática - NIVEL 2 - Programadores BIT",
    rules: [
      { system: 12, grades: [6], genre: [1, 2] },
      { system: 13, grades: [1, 2, 3], genre: [1, 2] },
    ],
  },
  {
    id: 8,
    name: "Informática - NIVEL 3 - Programadores BYTE",
    rules: [{ system: 13, grades: [4, 5, 6], genre: [1, 2] }],
  },
]);

let criteria = ref({
  system: 0,
  grade: 6,
  genre: 0,
});

const filterCategories = (categories, criteria) => {
  return categories.filter((category) => {
    return category.rules.some((rule) => {
      const systemMatch = rule.system === myLevel.value;
      const gradeMatch = rule.grades.includes(myGrade.value);
      const genreMatch = rule.genre.includes(myStudent.value.gender);
      return systemMatch && gradeMatch && genreMatch;
    });
  });
};

const filteredCategories = computed(() =>
  filterCategories(categories.value, criteria.value)
);
//------- end section

const emits = defineEmits(["reload-list", "close-mdl"]);

const modeList = ref(true);
const modeAdd = ref(false);
const changeMode = () => {
  modeList.value = !modeList.value;
  modeAdd.value = !modeAdd.value;
};

const idCard = ref("");
const complement = ref("");

const findPerson = () => {
  if (!idCard.value) {
    toast.warning("Ingrese un documento de identidad");
    return;
  }

  //--- check if exist records // document + complement
  if (!false) {
    changeMode();
    return;
  }

  //--- refresh list tutors
  console.log("findPerson", idCard.value);
};

const category = ref();

const addNewTutor = async () => {
  //
  const request = {
    participant_id: props.student.code,
    institution_id: props.codeSie,
    grade_id: 0,
    level_id: props.codeLevel,
    parallel_id: 0,
    course_id: props.codeCourse,
    name: props.student.name,
    first_last: props.student.first_last,
    second_last: props.student.second_last,
    rude: props.student.rude,
    category_id: category.value,
    system_id: 1,
    genere_id: props.student.genere_id,
    user_id: localStorage.getItem("userId"),
  };

  await axios
    .post("/structure/add-student", request)
    .then((response) => {
      toast.success("Registros Salvado!");
      category.value = null;
      emits("close-mdl");
    })
    .catch(function (error) {
      toast.error("Registro fallido!");
    });
};

const closeModal = () => {
  console.log("closeModal ... ");
  emits("close-mdl");
};
</script>
