<template>
  <div class="card mb-0">
    <div class="card-header d-flex">
      <h5>Estudiantes</h5>
      <div class="text-center">
        <div v-if="statusEnrollment">
          <button class="btn btn-success" @click="downloadReport">
            Reporte Inscritos ELO
          </button>
        </div>
      </div>

      <span class="f-14 pull-right mt-0">
        <div v-if="!statusEnrollment">
          <button
            class="btn btn-primary"
            @click="saveInscription"
            :disabled="statusEnrollment"
          >
            Guardar
          </button>
          <button
            class="btn btn-success"
            @click="consolidado"
            :disabled="statusEnrollment"
          >
            Concluir
          </button>
        </div>
        <div v-else>
          <h6 class="text-success">Inscripción Cerrada</h6>
        </div>
      </span>
    </div>
    <div class="card-body">
      <Students
        :students="students"
        @toggleParticipant="refreshParticipantList"
        :participantSelected="participantSelected"
        :statusEnrollment="statusEnrollment"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, inject, ref, defineEmits } from "vue";
import Students from "./Students.vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";

const swal = inject("$swal");

const emit = defineEmits(["changeStatusEnrollment", "getReport"]);

const props = defineProps({
  students: Array,
  participantList: Array,
  participantSelected: Array,
  statusEnrollment: Boolean,
});

const students = computed(() => {
  return props.students;
});

async function saveInscription() {
  const request = {
    participants: participantList.value,
    institution_id: localStorage.getItem("place"),
    level: 0,
    grade: 0,
    parallel: 0,
    course: 1,
    phase: 3,
    userId: localStorage.getItem("userId"),
  };
  console.log("request value", request);
  await axios
    .post("/enrollment-list", request)
    .then((response) => {
      toast.success("Registros Salvados!");
    })
    .catch(function (error) {
      console.log(error);
    });
}
function consolidado() {
  const request = {
    institution_id: localStorage.getItem("place"),
    course: 1,
    phase: 3,
  };
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se clasificara el listado de alumnos",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await saveInscription();
        axios
          .post("/enrollment-list/close-enrollment", request)
          .then((response) => {
            toast.success("Inscripción exitosa!!!");
            emit("changeStatusEnrollment", true);
          })
          .catch(function (error) {
            toast.error("Inscripción fallida!!!");
          });
      }
    });
}

const participantList = computed(() => {
  return props.participantList;
});

function refreshParticipantList(participant) {
  const removeItemIndex = participantList.value.indexOf(participant);
  const exist = participantList.value.find((item) => item === participant);
  if (exist) {
    participantList.value.splice(removeItemIndex, 1);
  } else {
    participantList.value.push(participant);
  }
  console.log("list", participantList.value);
}

function downloadReport() {
  emit("getReport");
}
</script>
