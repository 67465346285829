<template>
  <Breadcrumbs main="Torneo" title="Reglas" />
  <div class="container-fluid">
    <div class="row project-cards">
      <TopMenu @reload-list="loadItems" />
      <Table :list="list" />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import axios from "@/includes/connection";

import TopMenu from "./components/TopMenu.vue";
import Table from "./components/Table.vue";

const list = ref([]);

onMounted(() => {
  loadItems();
});

function loadItems() {
  axios
    .get("/criteria")
    .then((response) => {
      console.log("loadItems", response.data);
      list.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
