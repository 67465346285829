<template>
  <div class="card" v-show="state">
    <div class="card-body">
      <div class="email-app-sidebar left-bookmark">
        <ul class="nav main-menu contact-options" role="tablist">
          <li class="nav-item">
            <div style="padding: 30px 20px">
              <label for="">Código SIE</label>
              <input type="number" class="form-control" v-model="rueValue" />
              <button class="btn btn-primary col-12" @click="findRue">
                Buscar
              </button>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, defineEmits, defineProps } from "vue";
import { toast } from "vue3-toastify";

const props = defineProps({
  state: Boolean,
});

const emit = defineEmits(["getSie"]);

const rueValue = ref("");
const findRue = () => {
  if (rueValue.value) emit("getSie", rueValue.value);
  else toast.error("Ingrese un código SIE");
};

const list = computed(() => {
  return props.list;
});
</script>
