<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive signal-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Nombre Completo</th>
              <th scope="col">Usuario</th>
              <th scope="col">Rol</th>
              <th scope="col">Activo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in paginatedItems" :key="user.id">
              <th scope="row">{{ user.id }}</th>
              <td>{{ user.name }}</td>
              <td>{{ user.username }}</td>
              <td class="d-flex align-items-center">
                <button
                  class="btn btn-secondary"
                  type="button"
                  data-bs-toggle="modal"
                  data-original-title="test"
                  data-bs-target="#exampleModal"
                >
                  <i class="fa fa-th-list"></i>
                </button>
              </td>
              <td>
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="changeStatusUser(user.id)"
                      :checked="user.has_access"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <roleManagerModal />
        </div>
      </div>
      <div class="card-footer">
        <pagination
          class="card"
          style="padding-bottom: 10px"
          :totalItems="users.length"
          :itemsPerPage="itemsPerPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import roleManagerModal from "./elements/rolemanagermodal.vue";
import Pagination from "@/pages/components/Pagination.vue";
import axios from "@/includes/connection";
import { ref, onMounted, inject, computed } from "vue";

const swal = inject("$swal");

let users = ref([]);
let modalData = ref(null);

onMounted(() => {
  loadUsers();
});

function loadUsers() {
  axios
    .get("/user/principal")
    .then((response) => {
      if (response.data) {
        users.value = response.data;
      }
    })
    .catch(function (error) {
      console.log(data);
    });
}

function changeStatusUser(userid) {
  action_change_status_user(userid);
}

function action_change_status_user(userid) {
  axios
    .delete("/user/" + userid)
    .then((response) => {
      this.loadUsers();
    })
    .catch(function (error) {
      swal({
        text: "No se pudo cambiar el estado del usuario!",
      });
    });
}

function show_user_roles_modal(user) {
  modalData = user;
}

let currentPage = ref(1);
let itemsPerPage = ref(25);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return users.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
