<template>
  <div class="card" v-show="state">
    <div class="card-body">
      <div class="email-app-sidebar left-bookmark">
        <div class="media-size-email text-center">
          <img
            class="me-3 rounded-circle"
            src="@/assets/school.png"
            alt="School"
            style="width: 60px"
          />
        </div>
        <div class="media">
          <div class="media-body">
            <h6 class="f-w-700 text-center">{{ institution.name }}</h6>
            <p class="text-center kind-of-institution">
              {{ institution.type }}
            </p>
            <button
              class="btn btn-default col-12"
              @click="changeRue"
              :disabled="mode"
            >
              Cambiar Institución
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";

const emit = defineEmits(["changeState"]);

const props = defineProps({
  institution: Object,
  state: Boolean,
  mode: Boolean,
});

const changeRue = () => {
  emit("changeState");
};
</script>
<style></style>
