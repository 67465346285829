<template>
  <button class="btn btn-primary" type="button" @click="createModal.show()">
    <i class="fa fa-plus"> </i>
  </button>

  <div
    class="modal fade"
    ref="eventModal"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenter"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <vee-form :validation-schema="placeSchema" @submit="registerPlace">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crear Regla</h5>
            <button
              class="btn-close"
              type="button"
              @click="createModal.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label>Tipo</label>
                  <vee-field
                    as="select"
                    name="type"
                    class="form-select"
                    v-model="ruleSelected"
                    @change="selectType"
                  >
                    <option selected disabled>Seleccione una opción</option>
                    <option
                      v-for="rule in rulesList"
                      :value="rule.id"
                      :key="rule.id"
                    >
                      {{ rule.rule }}
                    </option>
                  </vee-field>
                  <ErrorMessage class="text-danger" name="type" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label>Regla</label>
                  <vee-field as="select" name="rule" class="form-select">
                    <option selected disabled>Seleccione una opción</option>
                    <option
                      v-for="criteria in criteriaList"
                      :value="criteria.grado"
                      :key="criteria.id"
                    >
                      {{ criteria.grado }}
                    </option>
                  </vee-field>
                  <ErrorMessage class="text-danger" name="rule" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button class="btn btn-primary" type="submit">Registrar</button>
          </div>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch, defineProps } from "vue";
import axios from "@/includes/connection";
import { Modal } from "bootstrap";
import Criteria from "../Criteria.vue";

const swal = inject("$swal");
const createEventModal = ref(null);
const emits = defineEmits(["reload-list"]);

const props = defineProps({ categoryId: Number });
console.log("props criteria", props.categoryId);

function registerPlace(values, { resetForm }) {
  values.category_id = props.categoryId;
  const mytype = rulesList.value.find((rule) => {
    if (rule.id == ruleSelected.value) {
      return rule.rule;
    }
  });
  values.type = mytype.rule;
  console.log("myType", mytype);
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se registrara el nuevo registro?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Registrar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        console.log("values for place >> ", values);
        axios
          .post("/rules", values)
          .then((response) => {
            console.log("response", response);
            resetForm();
            swal
              .fire({
                title: "Registro Exitoso!!!",
                text: "Se registro de manera exitosa",
                icon: "success",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  emits("reload-list");
                  createModal.hide();
                }
              });
          })
          .catch(function (error) {
            console.log(error);
            swal.fire({
              title: "Registro fallido!!!",
              text: "No se pudo registrar",
              icon: "warning",
            });
          });
      }
    });
}

const eventModal = ref(null);
let createModal = null;
onMounted(() => {
  createModal = new Modal(eventModal.value);
  loadRules();
});

const ruleSelected = ref();
const rulesList = ref([]);
function loadRules() {
  axios
    .get("/criteria")
    .then((response) => {
      console.log("loadItems criteria", response.data);
      rulesList.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadGrades() {
  axios
    .get("/institution/list/grades")
    .then((response) => {
      console.log("loadItems grades", response.data);
      criteriaList.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadGenere() {
  criteriaList.value = [
    { id: 1, grado: "Masculino" },
    { id: 2, grado: "Femenino" },
  ];
}

const criteriaList = ref([]);
function selectType() {
  if (ruleSelected.value == 1) {
    loadGrades();
  }
  if (ruleSelected.value == 2) {
    loadGenere();
  }
}

const placeSchema = {
  rule: "required",
  type: "required",
};
</script>
