<template>
  <div>
    <Breadcrumbs main="Trevol" title="Inicio" />

    <div class="container-fluid">
      <div class="row widget-grid">
        <WelcomeCard />
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { toast } from "vue3-toastify";
import WelcomeCard from "./default/WelcomeCard.vue";

onMounted(() => {
  toast.success("Bienvenido usuario!");
});
</script>
