<template>
  <div class="col-xl-12 xl-100 col-lg-12 box-col-12">
    <div class="card">
      <div class="card-header">
        <h5 class="pull-left"><i class="fa fa-users"></i> Lista de Usuarios</h5>
      </div>
      <div class="card-body">
        <div class="tabbed-card">
          <ul
            class="pull-right nav nav-pills nav-primary"
            id="pills-clrtab"
            role="tablist"
          >
            <li class="nav-item" v-on:click="changetab('timeline')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'timeline' }"
                id="pills-clrhome-tab"
                data-bs-toggle="pill"
                href="#pills-clrhome"
                role="tab"
                aria-controls="pills-clrhome"
                aria-selected="true"
                >Ministerio de Educacion</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('about')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'about' }"
                id="pills-clrprofile-tab"
                data-bs-toggle="pill"
                href="#pills-clrprofile"
                role="tab"
                aria-controls="pills-clrprofile"
                aria-selected="false"
                >Directores UE</a
              >
            </li>
            <li class="nav-item" v-on:click="changetab('friends')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'friends' }"
                id="pills-clrcontact-tab"
                data-bs-toggle="pill"
                href="#pills-clrcontact"
                role="tab"
                aria-controls="pills-clrcontact"
                aria-selected="false"
                >Tecnico Distrital
              </a>
            </li>
            <li class="nav-item" v-on:click="changetab('tec-dep')">
              <a
                class="nav-link"
                :class="{ 'show active': showtab == 'tec-dep' }"
                id="pills-clrcontact-tab"
                data-bs-toggle="pill"
                href="#pills-clrcontact"
                role="tab"
                aria-controls="pills-clrcontact"
                aria-selected="false"
                >Tecnico Departamental
              </a>
            </li>
          </ul>
          <div class="tab-content" id="pills-clrtabContent">
            <div
              class="tab-pane fade"
              :class="{ 'show active': showtab == 'timeline' }"
              id="pills-clrhome"
              role="tabpanel"
              aria-labelledby="pills-clrhome-tab"
            >
              <div class="container-fluid basic_table">
                <minEduUsers />
              </div>
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': showtab == 'about' }"
              id="pills-clrprofile"
              role="tabpanel"
              aria-labelledby="pills-clrprofile-tab"
            >
              <siePrincipal />
            </div>
            <div
              class="tab-pane fade"
              :class="{ 'show active': showtab == 'friends' }"
              id="pills-clrcontact"
              role="tabpanel"
              aria-labelledby="pills-clrcontact-tab"
            >
              <sieDistrital />
            </div>

            <div
              class="tab-pane fade"
              :class="{ 'show active': showtab == 'tec-dep' }"
              id="pills-clrcontact"
              role="tabpanel"
              aria-labelledby="pills-clrcontact-tab"
            >
              <sieDepartamental />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import minEduUsers from "./mineduusers.vue";
import genericUsers from "./generic-users.vue";
import sieDepartamental from "./sieDepartamental.vue";
import sieDistrital from "./sieDistrital.vue";
import siePrincipal from "./siePrincipal.vue";
export default {
  data() {
    return {
      showtab: "timeline",
    };
  },
  methods: {
    changetab(tab) {
      this.showtab = tab;
    },
  },
  components: {
    minEduUsers,
    sieDepartamental,
    sieDistrital,
    siePrincipal,
  },
};
</script>
