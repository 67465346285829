import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import "@/assets/scss/app.scss";
import store from "./store";
import Breadcrumbs from "./components/bread_crumbs";
import VueApexCharts from "vue3-apexcharts";
// import "bootstrap";
import settingPage from "./components/settingPage.vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import "brackets-viewer/dist/brackets-viewer.min.css";
import bcard from "./components/b-card.vue";
import axios from "axios";
import VeeValidatePlugin from "./includes/validation";

// form builder
import ElementPlus from "element-plus";
import "element-plus/theme-chalk/index.css";
import SvgIcon from "@/form_builder/components/form/SvgIcon/index.vue";
import { VueDraggableNext } from "vue-draggable-next";
import DraggableWarp from "@/form_builder/components/form/DraggableWarp.vue";
import OptionInput from "@/form_builder/components/form/OptionInput.vue";
import UploadWarp from "@/form_builder/components/form/elementWarp/UploadWarp.vue";

import es from "element-plus/es/locale/lang/es";
import hljs from "highlight.js";
import "highlight.js/styles/googlecode.css";

// specific src
import Vue3Toasity from "@/includes/toast.conf";
import PrimeVue from "primevue/config";
import "primevue/resources/themes/saga-blue/theme.css";
import Button from "primevue/button";
import Tree from "primevue/tree";

const axiosInstance = axios.create({
  withCredentials: true,
});

axios.defaults.baseURL = process.env.VUE_APP_SERVER_SIDE_URL;

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.use(ElementPlus, {
  locale: es,
});
app.component("draggable", VueDraggableNext);
app.component("draggable-warp", DraggableWarp);
app.component("option-input", OptionInput);
app.component("svg-icon", SvgIcon);
app.component("upload-warp", UploadWarp);

app.directive("highlight", function (el) {
  let blocks = el.querySelectorAll("pre code");
  setTimeout(() => {
    blocks.forEach((block) => {
      hljs.highlightBlock(block);
    });
  }, 200);
});

app
  .use(VeeValidatePlugin)
  .use(PrimeVue)
  .component("Button", Button)
  .component("Tree", Tree)
  .use(store)
  .use(VueSweetalert2)
  .use(router)
  .use(Vue3Toasity)
  .use(require("vue-chartist"))
  .component("settingPage", settingPage)
  .component("b-card", bcard)
  .component("Breadcrumbs", Breadcrumbs)
  .component("apexchart", VueApexCharts)
  .mount("#app");
