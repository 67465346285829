let menuProfile = {};

console.log("menu section ... ", localStorage.getItem("type"));
console.log("menu section profile ... ", localStorage.getItem("profile"));

if (localStorage.getItem("type") == 2) {
  const profile = localStorage.getItem("profile");
  console.log("menu section 2 ... ", typeof profile);
  switch (localStorage.getItem("profile")) {
    case "7": //departamental
      menuProfile = {
        data: [
          {
            headTitle1: "General",
            headTitle2: "Dashboards,Widgets & Layout.",
            type: "headtitle",
          },
          {
            title: "Torneo Ajedrez",
            icon: "stroke-task",
            iconf: "fill-task",
            type: "sub",
            active: false,
            children: [
              {
                path: "/institution/departamental",
                title: "Fase Departamental",
                type: "link",
                active: false,
              },
              {
                path: "/institution/elo-validation",
                title: "Validación ELO",
                type: "link",
                active: false,
              },
            ],
          },
        ],
      };
      break;
    case "9": //director
      console.log(
        "menu section director ... ",
        localStorage.getItem("profile")
      );
      const currentDate = new Date();
      const dateToCompare = new Date("2024-07-17"); // Example date to compare with

      if (dateToCompare > currentDate) {
        console.log("The date to compare is in the future.");
        menuProfile = {
          data: [
            {
              headTitle1: "General",
              headTitle2: "Dashboards,Widgets & Layout.",
              type: "headtitle",
            },
            {
              title: "Torneo Ajedrez",
              icon: "stroke-task",
              iconf: "fill-task",
              type: "sub",
              active: false,
              children: [
                {
                  path: "/institution/enrollment",
                  title: "Fase Local",
                  type: "link",
                  active: false,
                },
              ],
            },
          ],
        };
      } else {
        menuProfile = {
          data: [
            {
              headTitle1: "General",
              headTitle2: "Dashboards,Widgets & Layout.",
              type: "headtitle",
            },
            {
              title: "Olimpiadas Científicas",
              icon: "stroke-to-do",
              iconf: "fill-to-do",
              type: "sub",
              active: false,
              children: [
                {
                  path: "/scientific/principal-group",
                  title: "Pre-Inscripción grupos",
                  type: "link",
                  active: false,
                },
                {
                  path: "/scientific/principal-person",
                  title: "Pre-Inscripción persona",
                  type: "link",
                  active: false,
                },
              ],
            },
          ],
        };
      }

      break;
    case "10": //distrital
      menuProfile = {
        data: [
          {
            headTitle1: "General",
            headTitle2: "Dashboards,Widgets & Layout.",
            type: "headtitle",
          },
          {
            title: "Torneo Ajedrez",
            icon: "stroke-task",
            iconf: "fill-task",
            type: "sub",
            active: false,
            children: [
              {
                path: "/institution/distrital",
                title: "Fase Distrital",
                type: "link",
                active: false,
              },
            ],
          },
        ],
      };
      break;
  }
} else {
  //--localStorage.setItem("profile",
  const profile = localStorage.getItem("profile");
  if (profile == 3) {
    menuProfile = {
      data: [
        {
          headTitle1: "General",
          headTitle2: "Dashboards,Widgets & Layout.",
          type: "headtitle",
        },
        {
          title: "Torneo Ajedrez",
          icon: "stroke-task",
          iconf: "fill-task",
          type: "sub",
          active: false,
          children: [
            {
              path: "/institution/nacional",
              title: "Fase Nacional",
              type: "link",
              active: false,
            },
          ],
        },
      ],
    };
  } else {
    menuProfile = {
      data: [
        {
          headTitle1: "General",
          headTitle2: "Dashboards,Widgets & Layout.",
          type: "headtitle",
        },

        {
          title: "Administración",
          icon: "stroke-user",
          iconf: "fill-user",
          type: "sub",
          active: false,
          children: [
            {
              path: "/users/profile",
              title: "Usuarios",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Eventos",
          icon: "stroke-project",
          iconf: "fill-project",
          type: "sub",
          active: false,
          children: [
            {
              path: "/events/list",
              title: "Lista de Eventos",
              type: "link",
              active: false,
            },
            {
              path: "/events/enrollments",
              title: "Formularios de Inscripción",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Paramétricas",
          icon: "stroke-table",
          iconf: "fill-table",
          type: "sub",
          active: false,
          children: [
            {
              path: "/config/kind-event",
              title: "Tipo Evento",
              type: "link",
              active: false,
            },
            {
              path: "/config/places",
              title: "Ambientes",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Bandeja",
          icon: "stroke-email",
          iconf: "fill-email",
          type: "sub",
          active: false,
          children: [
            {
              path: "/inbox/request",
              title: "Solicitudes",
              type: "link",
              active: false,
            },
            {
              path: "/inbox/history",
              title: "Historial",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Olimpiadas",
          icon: "stroke-calendar",
          iconf: "fill-calendar",
          type: "sub",
          active: false,
          children: [
            {
              path: "/olympiad/chess",
              title: "Ajedrez",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Torneo Ajedrez",
          icon: "stroke-task",
          iconf: "fill-task",
          type: "sub",
          active: false,
          children: [
            {
              path: "/institution/enrollment",
              title: "Fase Local",
              type: "link",
              active: false,
            },
            {
              path: "/institution/distrital",
              title: "Fase Distrital",
              type: "link",
              active: false,
            },
            {
              path: "/institution/departamental",
              title: "Fase Departamental",
              type: "link",
              active: false,
            },
            {
              path: "/institution/elo-validation",
              title: "Validación ELO",
              type: "link",
              active: false,
            },
            {
              path: "/institution/nacional",
              title: "Fase Nacional",
              type: "link",
              active: false,
            },
            {
              path: "/manager/report",
              title: "Reporte Torneo",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Gestor Torneos",
          icon: "stroke-to-do",
          iconf: "fill-to-do",
          type: "sub",
          active: false,
          children: [
            {
              path: "/manager/tournaments",
              title: "Torneos",
              type: "link",
              active: false,
            },
            {
              path: "/manager/rule",
              title: "Reglas",
              type: "link",
              active: false,
            },
          ],
        },
        {
          title: "Olimpiadas Científicas",
          icon: "stroke-to-do",
          iconf: "fill-to-do",
          type: "sub",
          active: false,
          children: [
            {
              path: "/scientific/group",
              title: "Pre-Inscripción grupos",
              type: "link",
              active: false,
            },
            {
              path: "/scientific/person",
              title: "Pre-Inscripción persona",
              type: "link",
              active: false,
            },
          ],
        },
      ],
    };
  }
}

export const menuItems = menuProfile;
