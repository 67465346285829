import helper from "@/form_builder/components/form/ui/helper.js";

export default {
  vue_tag: "input",
  tag: "el-switch",
  name: "Tipo Switch",
  __openRules: false,
  tagIcon: "switch",
  ctrlBtn: true,
  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    // labelWidth: helper.input_number("Ancho etiqueta", 100),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),
    // disabled: helper.input_boolean("是否禁用", false),
    // loading: helper.input_boolean("loading", false),
    // "validate-event": helper.input_boolean(
    //   "改变 switch 状态时是否触发表单的校验",
    //   true
    // ),
  },
  slots: {},
  props: {},
  childrens: [],
};
