<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">ID</th>
              <th scope="col" class="text-center">Fase</th>
              <th scope="col" class="text-center">Orden</th>
              <th scope="col" class="text-center">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <th scope="row">{{ item.id }}</th>

              <td>{{ item.detail }}</td>
              <td>{{ item.order }}</td>

              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="changeState($event, item.id)"
                      :checked="checkStatus(item.id)"
                    />
                    <span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import axios from "@/includes/connection";

const props = defineProps({ list: Array, phaseId: Number });
const list = computed(() => {
  return props.list;
});

onMounted(() => {
  loadItems();
});

function checkStatus(code) {
  const out = selectedList.value.find((item) => item.phase_id == code);
  return out;
}

function changeState(event, code) {
  if (event.target.checked) {
    const data = {
      category_id: props.phaseId,
      phase_id: code,
    };
    axios
      .post("/phase-category", data)
      .then((response) => {
        console.log("update state", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    const out = selectedList.value.find((item) => {
      if (item.phase_id == code) {
        return item;
      }
    });
    if (out) {
      axios
        .delete("/phase-category/" + out.id)
        .then((response) => {
          console.log("update state", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
  loadItems();
}

const selectedList = ref([]);
function loadItems() {
  axios
    .get("/phase-category/" + props.phaseId)
    .then((response) => {
      console.log("loadItems selected", response.data);
      selectedList.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  console.log("load pagination", list.value);
  return list.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
