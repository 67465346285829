import helper from "@/form_builder/components/form/ui/helper.js";
export default {
  vue_tag: "input",
  __ID: "",
  tag: "upload-warp",
  name: "Tipo archivo",
  __openRules: false,
  tagIcon: "input",
  ctrlBtn: true,
  defaultvalue: "",
  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    // labelWidth: helper.input_number("Ancho etiqueta", 100),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),

    uploadType: helper.input_radio(
      "Tipo archivo",
      [
        { value: "imagen", key: "image" },
        { value: "documento", key: "file" },
      ],
      "file"
    ),

    // action: helper.input_text(
    //   "servidor asociado",
    //   "https://www.vkandian.cn/index.php/index/upload"
    // ),
    // multiple: helper.input_boolean("multiples archivos", ""),
    // name: helper.input_text("referencia", "file"),
    // tip: helper.input_text("tips", ""),

    // "show-file-list": helper.input_boolean("file list", true),
    // drag: helper.input_boolean("dnd", false),
    // accept: helper.input_text("tipo archivo", ""),
    // "thumbnail-mode": helper.input_boolean("mostrar", false),
    // "list-type": helper.input_radio(
    //   "lista de modo",
    //   [
    //     { key: "text", value: "text" },
    //     { key: "picture", value: "picture" },
    //     { key: "picture-card", value: "picture-card" },
    //   ],
    //   "text"
    // ),
    // "auto-upload": helper.input_boolean("自动上传", true),
    // disabled: helper.input_boolean("禁用", false),
    limit: helper.input_number("cantidad de archivos", 1),
  },
  slots: {},
  props: {},
  childrens: [],
};
