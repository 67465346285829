<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">Operaciones</th>
              <th scope="col" class="text-center">Afiche</th>
              <th scope="col" class="text-center">Banner</th>
              <th scope="col" class="text-center">Nro</th>
              <th scope="col" class="text-center">Fecha Solicitud</th>
              <th scope="col" class="text-center">Evento</th>
              <th scope="col" class="text-center">Fecha Inicio</th>
              <th scope="col" class="text-center">Fecha Fin</th>
              <th scope="col" class="text-center">Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <td class="text-center">
                <div v-if="item.inbox > 1">
                  <div class="btn-group btn-sm" role="group" aria-label="Basic example">
                    <button class="btn btn-secondary" @click="doAction(item, 3)"><i class="fa fa-check"></i></button>
                    <button class="btn btn-secondary" @click="doAction(item, 4)"><i class="fa fa-question"></i></button>
                    <button class="btn btn-secondary" @click="doAction(item, 5)"><i class="fa fa-times"></i></button>
                  </div>
                </div>
                <div v-else><button class="btn btn-sm btn-primary" @click="doAction(item, 2)"><i class="fa fa-envelope-o"</i></button></div>
              </td>

              <td>
                <button
                  class="btn btn-secondary"
                  type="button"
                  @click="loadModal(item, 'Afiche', 'poster')"
                  :disabled="item.inbox == 1"
                >
                  <i class="fa fa-upload"></i>
                </button>
              </td>
              <td>
                <button
                  class="btn btn-success"
                  type="button"
                  @click="loadModal(item, 'Banner', 'banner')"
                  :disabled="item.inbox == 1"
                >
                  <i class="fa fa-upload"></i>
                </button>
              </td>

              <th scope="row">{{ item.id }}</th>
              <td>{{ formatDate(item.init_date) }}</td>
              <td>{{ item.title }}</td>
              <td>{{ formatDate(item.init_date) }}</td>
              <td>{{ formatDate(item.end_date) }}</td>
              <td>Interno</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
  <UpdateFileModal
    ref="thisModal"
    :name="modalName"
    :event="eventSelect"
    :mode="modalMode"
    @reloadList="loadItems"
  />
</template>

<script setup>
import UpdateFileModal from "./UpdateFileModal.vue";
import { onMounted, ref, inject, computed } from "vue";
import axios from "@/includes/connection";
import { toast } from "vue3-toastify";

import Pagination from "@/pages/components/Pagination.vue";

const list = ref([]);
const recive = ref({ 2: "recibir" });
const accion = ref({ 3: "Aprobar", 4: "Observar", 5: "Rechazar" });

const swal = inject("$swal");

let thisModal = ref(null);

onMounted(() => {
  loadItems();
});

const eventSelect = ref({});
const modalName = ref();
const modalMode = ref();
function loadModal(event, name, mode) {
  thisModal.value.show();
  console.log("launch modal with all information", event);
  eventSelect.value = event;
  modalName.value = name;
  modalMode.value = mode;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}

function loadItems() {
  axios
    .get("/event/comunicacion")
    .then((response) => {
      console.log("list", response.data);
      if (response.data) {
        list.value = response.data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

const recibeAction = ref([{ id: 2, name: "Recibir" }]);

const actionList = ref([
  { id: 3, name: "Aprobar" },
  { id: 4, name: "Observar" },
  { id: 5, name: "Rechazar" },
]);

const doAction = (event, doAction) => {
  console.log('this is an action', doAction);
  const storage = event.storage;

  if (doAction == 3 && Object.keys(storage).length < 3) {
    toast.error(
      "Para aprobar el evento. debe tener cargado el Banner y afiche correspondiente."
    );
    return;
  }

  const dataJson = {
    event_id: event.id,
    step_id: 1,
    action_id: doAction,
    state: 1,
  };
  axios
    .post("/flow-event", dataJson)
    .then((response) => {
      console.log("response", response);
      loadItems();
      toast.success("Registro Exitoso!");
    })
    .catch(function (error) {
      toast.error("Registro Fallido!");
    });
};

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return list.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
