<template>
  <Breadcrumbs title="Institución" />

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <div class="col-xl-12 col-md-12 box-col-12">
          <div class="email-right-aside bookmark-tabcontent contacts-tabs">
            <div class="card email-body radius-left">
              <div class="ps-0">
                <div class="tab-content">
                  <div
                    class="tab-pane fade"
                    v-bind:class="
                      activeclass === 'pills-personal-tab' ? 'active show' : ''
                    "
                    id="pills-personal"
                    role="tabpanel"
                    aria-labelledby="pills-personal-tab"
                  >
                    <personalContact
                      :students="listStudent"
                      :participantList="participantList"
                      :participantSelected="participantSelected"
                      :statusEnrollment="statusEnrollment"
                      @changeStatusEnrollment="changeStatus"
                      @reloadList="getParticipantSelected"
                      @getReport="generateReport"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-show="false">
    <div class="report" ref="reportEnrollment">
      <img src="@/assets/minedu.png" alt="minedu-logo" style="height: 60px" />
      <div class="text-center" style="padding-top: 20px">
        <h5>MINISTERIO DE EDUCACIÓN</h5>
        <h5>REPORTE SELECCIONADOS NIVEL NACIONAL</h5>
        <h5 v-if="systemId == 1">SUBSISTEMA EDUCACIÓN REGULAR</h5>
        <h5 v-if="systemId == 2">SUBSISTEMA EDUCACIÓN ALTERNATIVA</h5>
        <h5 v-if="systemId == 4">SUBSISTEMA EDUCACIÓN ESPECIAL</h5>
      </div>
      <div v-if="systemId == 1">
        <div
          class="no-page-break"
          style="padding-top: 15px; text-align: center"
        >
          <p style="text-align: center">
            A. Estudiantes de Educación Primaria Comunitaria Vocacional (Sin
            ELO)
          </p>
          <table class="content" border="1" width="100%">
            <tr>
              <th style="background: cyan">CATEGORÍA</th>
              <th>NOMBRE Y APELLIDO</th>
              <th>CI</th>
              <th>RUDE</th>
              <th>DISTRITO</th>
              <th>SIE</th>
              <th>UNIDAD EDUCATIVA</th>
            </tr>
            <tr
              v-for="(participant, index) in primariaList"
              key="participant.carnet"
              class="no-page-break"
            >
              <td>{{ participant.category }}</td>
              <td>{{ participant.full_name }}</td>
              <td>{{ participant.carnet_identidad }}</td>
              <td>{{ participant.codigo_rude }}</td>
              <td>{{ participant.lugar }}</td>
              <td>{{ participant.sie }}</td>
              <td>{{ participant.institucioneducativa }}</td>
            </tr>
          </table>
        </div>
        <div
          class="no-page-break"
          style="padding-top: 15px; text-align: center"
        >
          <p style="text-align: center">
            B. Estudiantes de Educación Secundaria Comunitaria Productiva (Sin
            ELO)
          </p>
          <table class="content" border="1" width="100%">
            <tr>
              <th style="background: cyan">CATEGORÍA</th>
              <th>NOMBRE Y APELLIDO</th>
              <th>CI</th>
              <th>RUDE</th>
              <th>DISTRITO</th>
              <th>SIE</th>
              <th>UNIDAD EDUCATIVA</th>
            </tr>
            <tr
              v-for="(participant, index) in secundaria"
              key="participant.carnet"
              class="no-page-break"
            >
              <td>{{ participant.category }}</td>
              <td>{{ participant.full_name }}</td>
              <td>{{ participant.carnet_identidad }}</td>
              <td>{{ participant.codigo_rude }}</td>
              <td>{{ participant.lugar }}</td>
              <td>{{ participant.sie }}</td>
              <td>{{ participant.institucioneducativa }}</td>
            </tr>
          </table>
        </div>
        <div
          class="no-page-break"
          style="padding-top: 15px; text-align: center"
        >
          <p style="text-align: center">
            C. Estudiantes del Sistema Educativo Plurinacional Con ELO
          </p>
          <table class="content" border="1" width="100%">
            <tr>
              <th style="background: cyan">CATEGORÍA</th>
              <th>NOMBRE Y APELLIDO</th>
              <th>CI</th>
              <th>RUDE</th>
              <th>DISTRITO</th>
              <th>SIE</th>
              <th>UNIDAD EDUCATIVA</th>
            </tr>
            <tr
              v-for="(participant, index) in elo"
              key="participant.carnet"
              class="no-page-break"
            >
              <td>{{ participant.category }}</td>
              <td>{{ participant.full_name }}</td>
              <td>{{ participant.carnet_identidad }}</td>
              <td>{{ participant.codigo_rude }}</td>
              <td>{{ participant.lugar }}</td>
              <td>{{ participant.sie }}</td>
              <td>{{ participant.institucioneducativa }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="systemId == 2">
        <div
          class="no-page-break"
          style="padding-top: 15px; text-align: center"
        >
          <p style="text-align: center">
            A. Estudiantes de Centros de Educación Alternativa
          </p>
          <table class="content" border="1" width="100%">
            <tr>
              <th style="background: cyan">CATEGORÍA</th>
              <th>NOMBRE Y APELLIDO</th>
              <th>CI</th>
              <th>RUDE</th>
              <th>DISTRITO</th>
              <th>SIE</th>
              <th>UNIDAD EDUCATIVA</th>
            </tr>
            <tr
              v-for="(participant, index) in alternativa"
              key="participant.carnet"
              class="no-page-break"
            >
              <td>{{ participant.category }}</td>
              <td>{{ participant.full_name }}</td>
              <td>{{ participant.carnet_identidad }}</td>
              <td>{{ participant.codigo_rude }}</td>
              <td>{{ participant.lugar }}</td>
              <td>{{ participant.sie }}</td>
              <td>{{ participant.institucioneducativa }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div v-if="systemId == 4">
        <div
          class="no-page-break"
          style="padding-top: 15px; text-align: center"
        >
          <p style="text-align: center">
            A. Estudiantes con discapacidad visual y auditiva de CEE (Reglas
            FIDE)
          </p>
          <table class="content" border="1" width="100%">
            <tr>
              <th style="background: cyan">CATEGORÍA</th>
              <th>NOMBRE Y APELLIDO</th>
              <th>CI</th>
              <th>RUDE</th>
              <th>DISTRITO</th>
              <th>SIE</th>
              <th>UNIDAD EDUCATIVA</th>
            </tr>
            <tr
              v-for="(participant, index) in especial"
              key="participant.carnet"
              class="no-page-break"
            >
              <td>{{ participant.category }}</td>
              <td>{{ participant.full_name }}</td>
              <td>{{ participant.carnet_identidad }}</td>
              <td>{{ participant.codigo_rude }}</td>
              <td>{{ participant.lugar }}</td>
              <td>{{ participant.sie }}</td>
              <td>{{ participant.institucioneducativa }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="no-page-break" style="padding-top: 10px">
        <p>Es cuanto informo para fines consiguientes.</p>
      </div>

      <div class="no-page-break">
        <div style="padding-top: 100px" class="no-page-break">
          <table width="100%">
            <tr>
              <td width="30%"></td>
              <td width="40%">
                <div class="text-center">
                  <p class="signature-line">Firma y Sello/DDE</p>
                </div>
              </td>
              <td width="30%"></td>
            </tr>
          </table>
        </div>
        <div style="padding-top: 100px" class="no-page-break">
          <table width="100%">
            <tr>
              <td width="30%">
                <div class="text-center">
                  <p class="signature-line">Firma y Sello/SDER</p>
                </div>
              </td>
              <td width="40%">
                <div class="text-center">
                  <p class="signature-line">Firma y Sello/SDEAYE</p>
                </div>
              </td>
              <td width="30%">
                <div class="text-center">
                  <p class="signature-line">Firma y Sello/SDES</p>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div style="padding-top: 80px" class="no-page-break">
          <p style="font-style: italic; font-size: 10px; text-align: justify">
            <b>Nota.-</b>el documento debe llevar los sellos y las firmas de las
            autoridades mencionadas en la referencia.
          </p>
          <p>c.c. Archivo</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import personalContact from "./personalContact.vue";
import { onMounted, ref, computed } from "vue";
import axios from "@/includes/connection";

import { toast } from "vue3-toastify";

onMounted(() => {
  console.log("load one ... ");
  getStatusEnrollment();
  listparticipantsClasified();
  getParticipantSelected();
});

const listStudent = ref([]);

function listparticipantsClasified() {
  console.log("load two");
  axios
    .get(
      "/institution/list/national-clasified/" + localStorage.getItem("userId")
    )
    .then((response) => {
      console.log("out ", response.data);
      listStudent.value = response.data;
    })
    .catch(function (error) {
      toast.error("Falla al conectar con el server.");
    });
}

// --------------------------refactor

const activeclass = ref("pills-personal-tab");
const filtered = ref(false);

function active(item) {
  activeclass = item;
}

function changeStatus(status) {
  statusEnrollment.value = status;
  console.log("status", status);
}

const participantList = ref([]);

const statusEnrollment = ref([]);
function getStatusEnrollment() {
  axios
    .get("/enrollment-list/enrolled/national/" + localStorage.getItem("userId"))
    .then((response) => {
      console.log("status_en", response.data);
      statusEnrollment.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

// -------------------------- selected students
const participantSelected = ref([]);
function getParticipantSelected() {
  axios
    .get(
      "/enrollment-list/saved/departamental/" + localStorage.getItem("userId")
    )
    .then((response) => {
      console.log("getParticipantSelected", response.data);
      participantSelected.value = response.data;

      participantList.value = participantSelected.value.map((el) => {
        return el.participant_id;
      });
      console.log("list", participantList.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}

//--- report section
import html2pdf from "html2pdf.js";
const reportEnrollment = ref(null);

const systemId = ref(1);
const primariaList = ref([]);
const secundaria = ref([]);
const elo = ref([]);
const alternativa = ref([]);
const especial = ref([]);

async function getEnrolledList() {
  const user = localStorage.getItem("userId");
  await axios
    .get("/institution/get-user-system/" + user)
    .then(async (response) => {
      console.log("data location", response.data);
      systemId.value = response.data;
      primariaList.value = await findEstudent(1);
      secundaria.value = await findEstudent(2);
      elo.value = await findEstudent(5);
      alternativa.value = await findEstudent(3);
      especial.value = await findEstudent(4);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const findEstudent = async (cat) => {
  await axios
    .get("/institution/list-report-category/" + cat)
    .then((response) => {
      console.log("participantEnrolled", participantEnrolled.value);
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

const generateReport = async () => {
  console.log("generate report before");
  await getEnrolledList();
  html2pdf(reportEnrollment.value, {
    margin: 1,
    filename: systemId.value + ".pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  }).from(() => {
    const pdf = document.querySelector(".html2pdf"); // Select the generated PDF element
    const pdfBlob = pdf2blob(pdf); // Convert the PDF element to a Blob
    const pdfUrl = URL.createObjectURL(pdfBlob); // Create a URL for the Blob
    const downloadLink = document.createElement("a"); // Create a download link element
    downloadLink.href = pdfUrl;
    downloadLink.download = "report.pdf"; // Set the filename for download
    downloadLink.click(); // Simulate a click event to trigger the download
  });
};

function pdf2blob(pdf) {
  const pdfData = new Blob([pdf.outerHTML], { type: "application/pdf" });
  return pdfData;
}
</script>

<style scoped>
p {
  font-style: italic !important;
  font-size: 10px !important;
  /* text-align: justify !important; */
}
</style>
