<template>
  <div class="card">
    <div class="card-header">
      <h5>Formulario de Inscripción</h5>
    </div>
    <div class="text-center">
      <img
        class="card-img-top"
        :src="loadImage"
        alt="Banner Evento"
        style="width: 200px"
      />
    </div>

    <div class="card-body socialprofile filter-cards-view">
      <vee-form :validation-schema="baseFormSchema" @submit="send">
        <h6>Datos Minimos</h6>
        <div class="row">
          <div class="col-4">
            <div class="mb-3">
              <label>CI</label>
              <vee-field
                class="form-control"
                name="document"
                type="text"
                placeholder="Nro de Documento"
                v-maska
                data-maska="#######"
              />
              <ErrorMessage class="text-danger" name="document" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label>Complemento</label>
              <vee-field
                class="form-control"
                name="complement"
                type="text"
                placeholder="Complemento"
              />
              <ErrorMessage class="text-danger" name="complement" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <div
                class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml"
              >
                <br />
                <div class="radio radio-primary">
                  <vee-field
                    id="radioinline1"
                    type="radio"
                    name="nationality"
                    value="1"
                  />
                  <label class="mb-0" for="radioinline1">Nacional</label>
                </div>
                <div class="radio radio-primary">
                  <vee-field
                    id="radioinline2"
                    type="radio"
                    name="nationality"
                    value="2"
                  />
                  <label class="mb-0" for="radioinline2">Extranjero</label>
                </div>
              </div>
              <ErrorMessage class="text-danger" name="nationality" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="mb-3">
              <label>Apellido Paterno</label>
              <vee-field
                class="form-control"
                name="f_last_name"
                type="text"
                placeholder="Apellido Paterno"
              />
              <ErrorMessage class="text-danger" name="f_last_name" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label>Apellido Materno</label>
              <vee-field
                class="form-control"
                name="s_last_name"
                type="text"
                placeholder="Apellido Materno"
              />
              <ErrorMessage class="text-danger" name="s_last_name" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label>Nombre(s)</label>
              <vee-field
                class="form-control"
                name="name"
                type="text"
                placeholder="Nombre(s)"
              />
              <ErrorMessage class="text-danger" name="name" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="mb-3">
              <label>Celular/Teléfono</label>
              <vee-field
                class="form-control"
                name="phone"
                type="number"
                placeholder="Celular/Teléfono"
              />
              <ErrorMessage class="text-danger" name="phone" />
            </div>
          </div>
          <div class="col-8">
            <div class="mb-3">
              <label>Correo Electrónico</label>
              <vee-field
                class="form-control"
                name="email"
                type="email"
                placeholder="Correo Electrónico"
              />
              <ErrorMessage class="text-danger" name="email" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label>Ocupación</label>
              <vee-field class="form-control" name="job" type="text">
              </vee-field>
              <ErrorMessage class="text-danger" name="job" />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label>Institución</label>
              <vee-field class="form-control" name="institution" type="text">
              </vee-field>
              <ErrorMessage class="text-danger" name="institution" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="mb-3">
              <label>Cargo</label>
              <vee-field
                class="form-control"
                name="position"
                type="text"
                placeholder="Cargo"
              />
              <ErrorMessage class="text-danger" name="position" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label>Fecha de Nacimiento</label>
              <vee-field
                class="form-control"
                name="birth_date"
                type="date"
                placeholder="Fecha de Nacimiento"
              />
              <ErrorMessage class="text-danger" name="birth_date" />
            </div>
          </div>
          <div class="col-4">
            <div class="mb-3">
              <label>Departamento</label>
              <vee-field
                class="form-control"
                name="department"
                type="text"
                placeholder="Departamento"
              />
              <ErrorMessage class="text-danger" name="department" />
            </div>
          </div>
        </div>

        <DynamicForm :schema="formSchema"></DynamicForm>

        <button class="btn btn-primary text-center">
          Registrar Inscripción
        </button>
      </vee-form>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed } from "vue";
import axios from "@/includes/connection";
import DynamicForm from "@/auth/components/DynamicForm.vue";
import { useRoute } from "vue-router";
import { vMaska } from "maska";

import { toast } from "vue3-toastify";

const baseServerUrl = process.env.VUE_APP_SERVER_SIDE_URL;

const route = useRoute();

const loadImage = computed(() => {
  let imgPath = "commons/default.jpg";
  const myEvent = event.value;

  const arrFromObj = Object.keys(myEvent);
  console.log("count ", arrFromObj.length); // 3

  if (arrFromObj.length > 0) {
    console.log("is have");
    if (myEvent.storage) {
      const image = Object.values(myEvent.storage).filter((value) => {
        if (value.source == "banner") {
          return value;
        }
      });

      if (image.length) {
        // console.log("result: ", image[0].filename, image);
        imgPath = image[0].filename;
      }
    }
  } else {
    console.log("fail");
  }

  return `${baseServerUrl}/${imgPath}`;
});

onMounted(() => {
  getAditionalForm();
  getEvent();
  // console.log("params", route.params.id);
});

let baseFormSchema = ref({
  document: "required|min:7",
  complement: "",
  nationality: "required",
  f_last_name: "required|alpha_spaces|min:3",
  s_last_name: "required|alpha_spaces|min:3",
  name: "required|alpha_spaces|min:3",
  phone: "required|min:7",
  email: "required|email",
  job: "required|alpha_spaces|min:5",
  institution: "required|alpha_spaces|min:3",
  position: "required|alpha_spaces|min:3",
  birth_date: "required",
  department: "required|alpha_spaces|min:3",
});

const formSchema = ref({
  fields: [],
});

const send = (values, { resetForm }) => {
  values.event_id = route.params.id;
  console.log("values", values);
  // const params = { event_id: route.params.id };
  axios
    .post("/event-enrollment", values)
    .then((response) => {
      console.log("token", response.data);
      toast.success("Inscripción Exitosa");
      // resetForm();
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Falla en la inscripción!");
    });
};

function getAditionalForm() {
  axios
    .get(`/enrollment-form/${route.params.id}`)
    .then((response) => {
      let myArray = [];
      let loadAditional = JSON.parse(response.data.html);
      formSchema.value.fields = loadAditional;

      loadAditional.map((item) => {
        baseFormSchema.value[item.name] = "required";
      });
    })
    .catch(function (error) {
      console.log(error);
    });
}

const event = ref({});
function getEvent() {
  axios
    .get(`/event/${route.params.id}`)
    .then((response) => {
      event.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
