<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">RUDE</th>
              <th scope="col">Paterno</th>
              <th scope="col">Materno</th>
              <th scope="col">Nombre</th>
              <th scope="col" class="text-center">Genero</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, index) in paginatedItems" :key="student.code">
              <th>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</th>
              <th scope="row">{{ student.rude }}</th>
              <td>{{ student.first_last }}</td>
              <td>{{ student.second_last }}</td>
              <td>{{ student.name }}</td>
              <td class="text-center">
                <template v-if="student.gender == 1">
                  <img :src="male" style="width: 40px" />
                </template>
                <template v-else>
                  <img :src="female" style="width: 40px" />
                </template>
              </td>
              <td class="text-center">
                <button
                  class="btn btn-primary"
                  @click="showModal(student)"
                  v-if="!checkStatus(student.code)"
                  :disabled="state"
                >
                  Pre-Inscribir
                </button>
                <button class="btn btn-default" v-else>Registrado</button>
              </td>
            </tr>
          </tbody>
        </table>
        <pagination
          class="card"
          style="padding-bottom: 10px"
          :totalItems="students.length"
          :itemsPerPage="itemsPerPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
  </div>
  <ModalTutor
    ref="thisModal"
    :student="student"
    :enrolled="enrolled"
    @close-mdl="closeModal"
    :codeSie="codeSie"
    :codeLevel="codeLevel"
    :codeCourse="codeCourse"
    :codeGrade="codeGrade"
  />
</template>

<script setup>
import axios from "@/includes/connection";
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import ModalTutor from "./ModalTutor.vue";

const props = defineProps({
  students: Array,
  codeSie: Number,
  codeLevel: Number,
  codeCourse: Number,
  codeGrade: Number,
  enrolledList: Array,
  state: Boolean,
});
const emit = defineEmits(["refresh"]);

const male = ref(require("@/assets/male.png"));
const female = ref(require("@/assets/female.png"));

const student = ref({});

const students = computed(() => {
  return props.students;
});

// -- modal section
import { Modal } from "bootstrap";
let thisModal = ref(null);
let modal = null;

const showModal = (item) => {
  student.value = item;
  getActiveTutor();
  const modalElement = thisModal.value.$el;
  modal = new Modal(modalElement);
  modal.show();
};

const closeModal = () => {
  // const modalElement = thisModal.value.$el;
  // const modal = new Modal(modalElement);
  if (modal) {
    modal.hide();
    emit("refresh");
  }
};

const enrolled = ref(false);
const getActiveTutor = async () => {
  console.log("getActiveTutor", student.value);
  enrolled.value = props.state;
  // check if is enrolled
  // await axios
  //   .get("/structure/student-active-tutor/" + student.value.code)
  //   .then((response) => {
  //     tutor.value = response.data;
  //     console.log("active Tutor", tutor.value);
  //   })
  //   .catch(function (error) {
  //     console.log(error.response.data.message);
  //   });
};

// -- pagination section
let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return students.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}

function checkStatus(code) {
  const out = props.enrolledList.find((item) => item.participant_id == code);
  return out;
}
</script>
