<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Grupo</th>
              <th scope="col">Categoria</th>
              <th scope="col">Participantes</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, index) in paginatedItems" :key="student.code">
              <th>{{ index + 1 + (currentPage - 1) * itemsPerPage }}</th>
              <th scope="row">{{ student.group_name }}</th>
              <td>{{ student.group_project }}</td>
              <td>{{ student.student_list }}</td>
            </tr>
          </tbody>
        </table>
        <pagination
          class="card"
          style="padding-bottom: 10px"
          :totalItems="students.length"
          :itemsPerPage="itemsPerPage"
          @page-change="handlePageChange"
        />
      </div>
    </div>
  </div>
  <ModalTutor
    ref="thisModal"
    :student="student"
    :enrolled="enrolled"
    @close-mdl="closeModal"
  />
</template>

<script setup>
import axios from "@/includes/connection";
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import ModalTutor from "./ModalTutor.vue";

const props = defineProps({
  students: Array,
});
const emit = defineEmits(["toggleParticipant"]);

const male = ref(require("@/assets/male.png"));
const female = ref(require("@/assets/female.png"));

const student = ref({});

const students = computed(() => {
  return props.students;
});

// -- modal section
import { Modal } from "bootstrap";
let thisModal = ref(null);

const showModal = (item) => {
  student.value = item;
  getActiveTutor();
  const modalElement = thisModal.value.$el;
  const modal = new Modal(modalElement);
  modal.show();
};

const closeModal = () => {
  const modalElement = thisModal.value.$el;
  const modal = new Modal(modalElement);
  modal.hide();
};

const enrolled = ref(false);
const getActiveTutor = async () => {
  console.log("getActiveTutor", student.value);
  enrolled.value = false;
  // check if is enrolled
  // await axios
  //   .get("/structure/student-active-tutor/" + student.value.code)
  //   .then((response) => {
  //     tutor.value = response.data;
  //     console.log("active Tutor", tutor.value);
  //   })
  //   .catch(function (error) {
  //     console.log(error.response.data.message);
  //   });
};

// -- pagination section
let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return students.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
