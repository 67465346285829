import Vue3Toasity, { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  install(app) {
    app.use(Vue3Toasity, {
      autoClose: 2000,
      position: toast.POSITION.BOTTOM_RIGHT,
      toastId: "unique",
      style: {
        opacity: "1",
        userSelect: "initial",
      },
    });
  },
};
