import helper from "@/form_builder/components/form/ui/helper.js";

export default {
  vue_tag: "input",
  tag: "el-input-number",
  name: "Tipo Numero",
  __openRules: false,
  tagIcon: "number",
  __ID: "",
  defaultvalue: 0,

  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    // labelWidth: helper.input_number("Ancho etiqueta", 100),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),
    placeholder: helper.input_text("Ejemplo entrada", "Ingrese valor"),
    // "prefix-icon": helper.input_icon("框头部图标", ""),
    // "suffix-icon": helper.input_icon("框头部图标", ""),
    type: helper.input_select(
      "Tipo",
      [
        {
          key: "text",
          value: "texto",
        },
        {
          key: "textarea",
          value: "area de texo",
        },
        {
          key: "password",
          value: "contraseña",
        },
        {
          key: "number",
          value: "numero",
        },
      ],
      "number"
    ),
  },

  props: {},
  childrens: [],
  ctrlBtn: true,
  slots: {
    prefix: helper.input_text("框头部内容", ""),
    suffix: helper.input_text("框尾部内容", ""),
    prepend: helper.input_text("框前置内容", ""),
    append: helper.input_text("框后置内容", ""),
  },

  rule: {
    required: helper.input_boolean("必填", false),
    regList: {
      type: "reg",
    },
  },
};
