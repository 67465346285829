<template>
  <router-link to="/">
    <div style="text-align: center">
      <b style="padding: 0 20px; font-size: 20px; color: blue">Trevol</b>
    </div>
  </router-link>
</template>
<script>
export default {
  name: "Logo",
};
</script>
