<template>
  <div
    id="addModal"
    class="modal fade bd-example-modal-xl"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 style="font-size: 18px !important">Categorías Habilitadas</h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-5">
              <div>
                <b>Estudiante: </b>{{ student.first_last }}
                {{ student.second_last }} {{ student.name }}
              </div>
            </div>
            <div class="col-7">
              <div class="input-group mb-3">
                <span class="input-group-text"> Categoría </span>
                <select class="form-control col-4" :disabled="enrolled">
                  <option>No existen categorías para el estudiante</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="addNewTutor"
            v-if="!enrolled"
          >
            Registrar
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeModal"
            v-if="enrolled"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  getCurrentInstance,
} from "vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";
import Category from "@/pages/manager/category/Category.vue";

const { proxy } = getCurrentInstance();
const formattedDate = computed(() =>
  proxy.$formatDate(props.tutor[0].fecha_nacimiento_tut)
);

const emits = defineEmits(["reload-list", "close-mdl"]);

const props = defineProps({
  student: Object,
  enrolled: Boolean,
});

const modeList = ref(true);
const modeAdd = ref(false);
const changeMode = () => {
  modeList.value = !modeList.value;
  modeAdd.value = !modeAdd.value;
};

const idCard = ref("");
const complement = ref("");

const findPerson = () => {
  if (!idCard.value) {
    toast.warning("Ingrese un documento de identidad");
    return;
  }

  //--- check if exist records // document + complement
  if (!false) {
    changeMode();
    return;
  }

  //--- refresh list tutors
  console.log("findPerson", idCard.value);
};

const category = ref(100);

const addNewTutor = async () => {
  //
  const request = {
    participant_id: props.student.code,
    institution_id: 0,
    grade_id: 0,
    level_id: 0,
    parallel_id: 0,
    course_id: 0,
    name: props.student.name,
    first_last: props.student.first_last,
    second_last: props.student.second_last,
    rude: props.student.rude,
    category_id: category.value,
    system_id: 1,
    genere_id: props.student.genere_id,
    user_id: localStorage.getItem("userId"),
  };

  await axios
    .post("/structure/add-student", request)
    .then((response) => {
      toast.success("Registros Salvado!");
      emits["close-mdl"];
    })
    .catch(function (error) {
      toast.error("Registro fallido!");
    });
};

const closeModal = () => {
  console.log("closeModal ... ");
  emits["close-mdl"];
};
</script>
