<template>
  <Breadcrumbs title="registro de eventos" main="Eventos" />

  <div class="container-fluid">
    <div class="user-profile">
      <div class="row">
        <eventForm />
      </div>
    </div>
  </div>
</template>

<script>
import eventForm from "./eventForm.vue";
export default {
  components: {
    eventForm,
  },
};
</script>
