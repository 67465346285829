<template>
  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    ref="modalEle"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            Actualizar {{ name }}
          </h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="card papernote-wrap">
            <div class="card-body pt-0">
              <img
                class="banner-img img-fluid"
                :src="defImage"
                alt="multicolor background"
              />
              <div class="note-content mt-sm-4 mt-2">
                <div class="text-center">
                  <button
                    class="btn btn-primary"
                    :disabled="edit"
                    @click="enableEdit"
                  >
                    Modificar
                  </button>
                </div>
                <vee-form :validation-schema="imageFormSchema" @submit="send">
                  <div class="mb-3">
                    <label>Subir Nuevo Archivo</label>
                    <vee-field
                      class="form-control"
                      name="image"
                      type="file"
                      placeholder="Subir Nuevo"
                      :disabled="!edit"
                    />
                    <ErrorMessage class="text-danger" name="image" />
                  </div>
                  <div class="text-center">
                    <button
                      class="btn btn-success"
                      :disabled="!edit"
                      type="submit"
                    >
                      Registrar
                    </button>
                  </div>
                </vee-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Modal } from "bootstrap";
import {
  computed,
  defineProps,
  ref,
  onMounted,
  inject,
  defineEmits,
} from "vue";
import fileSender from "@/includes/fileconnection";
const props = defineProps({ event: Object, name: String, mode: String });
const emit = defineEmits(["reloadList"]);

const baseServerUrl = process.env.VUE_APP_SERVER_SIDE_URL;
const swal = inject("$swal");

const modalEle = ref(null);
let modalObj = null;

onMounted(() => {
  modalObj = new Modal(modalEle.value);
});

function _show() {
  modalObj.show();
}

let edit = ref(false);
const name = computed(() => {
  return props.name;
});

const event = computed(() => {
  return props.event;
});

const defImage = computed(() => {
  let imgPath = "commons/default.jpg";
  if (props.event.storage) {
    console.log("modeImg", props.mode);
    const image = Object.values(props.event.storage).filter((value) => {
      if (value.source == props.mode) {
        return value;
      }
    });

    if (image.length) {
      console.log("result: ", image[0].filename, image);
      imgPath = image[0].filename;
    }
  }

  return `${baseServerUrl}/${imgPath}`;
});

function enableEdit() {
  edit.value = true;
}

const imageFormSchema = {
  image: "required|image|size:1024",
};

function send(values, { resetForm }) {
  swal
    .fire({
      title: "¿Esta seguro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Registrar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("source_id", props.event.id);
        formData.append("source", props.mode);

        Object.entries(values).forEach(([key, value]) => {
          formData.append(key, value);
        });

        console.log("req", formData);

        fileSender
          .post("/storage", formData)
          .then((response) => {
            console.log("response", "");
            resetForm();
            swal.fire({
              title: "Registro Exitoso!!!",
              icon: "success",
            });
            edit.value = false;
            emit("reloadList");
            modalObj.hide();
          })
          .catch(function (error) {
            swal.fire({
              title: "Registro fallido!!!",
              icon: "warning",
            });
          });
      }
    });
}

defineExpose({ show: _show });
</script>
