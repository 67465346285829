import helper from "@/form_builder/components/form/ui/helper.js";

import elInput from "./element/form/elInput";
import elCheckboxGroup from "./element/form/elCheckboxGroup";
import elInputNumber from "./element/form/elInputNumber";
import elRadioGroup from "./element/form/elRadioGroup";
import elSelect from "./element/form/elSelect";
import elSlider from "./element/form/elSlider";
import elSwitch from "./element/form/elSwitch";
import elTimeSelect from "./element/form/elTimeSelect";
import elUpload from "./element/form/elUpload";

// 表单属性【右面板】
const formConf = {
  tag: "el-form",
  props: {},
  childrens: [],
  __rules: {},
  attrs: {
    __formRef: helper.input_text("Nombre", "refForm"),
    __formModel: helper.input_text("form model", "formData"),
    // size: helper.input_radio(
    //   "form size",
    //   [
    //     {
    //       key: "medium",
    //       value: "medium",
    //     },
    //     {
    //       key: "small",
    //       value: "small",
    //     },
    //     {
    //       key: "mini",
    //       value: "mini",
    //     },
    //   ],
    //   "medium"
    // ),
    // labelPosition: helper.input_radio(
    //   "alignment",
    //   [
    //     {
    //       key: "right",
    //       value: "right",
    //     },
    //     {
    //       key: "left",
    //       value: "left",
    //     },
    //     {
    //       key: "top",
    //       value: "top",
    //     },
    //   ],
    //   "right"
    // ),
    // labelWidth: helper.input_number("ancho", 100),
    // disabled: helper.input_boolean("desabilitar", false),
    // inline: helper.input_boolean("modo", false),
    // hideRequiredAsterisk: helper.input_boolean("requerido", false),
    __formBtns: helper.input_boolean("show button", true),
  },
};

const elements = {
  // base: { eles: [], title: "基本组件" },
  form: { eles: [], title: "Elementos" },
};

elements.form.eles.push(elInput);
elements.form.eles.push(elCheckboxGroup);
// elements.form.eles.push(elColorPicker);
elements.form.eles.push(elInputNumber);
elements.form.eles.push(elRadioGroup);
// elements.form.eles.push(elRate);
elements.form.eles.push(elSelect);
elements.form.eles.push(elSlider);
elements.form.eles.push(elSwitch);
// elements.form.eles.push(elTimePicker);
elements.form.eles.push(elTimeSelect);
elements.form.eles.push(elUpload);

// let files = import.meta.globEager("./element/form/*.js");
// let files = import.meta.glob("./element/form/*.js", { eager: true });
// let files = import.meta.hot.accept("./element/form/*.js");

// for (const key in files) {
//   elements.form.eles.push(files[key].default);
// }
// files = import.meta("./element/base/*.js");
// files = importAllModules("./element/base/*.js");

// for (const key in files) {
//   elements.base.eles.push(files[key].default);
// }

// elements.base.eles.push(elButton);
// elements.base.eles.push(elDivider);
// elements.base.eles.push(elRow);
// elements.base.eles.push(elScrollbar);

export { elements, formConf };
