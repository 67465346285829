<template>
  <div class="card" v-show="state">
    <div class="card-body">
      <div class="email-app-sidebar left-bookmark">
        <div class="media-size-email text-center">
          <img
            class="me-3 rounded-circle"
            src="@/assets/school.png"
            alt="School"
            style="width: 60px"
          />
        </div>
        <div class="media">
          <div class="media-body">
            <div style="padding: 30px 20px">
              <select
                class="form-select"
                @change="selectSchool()"
                v-model="selectItem"
              >
                <option value="0" disabled>Seleccione Institución</option>
                <option
                  v-for="item in institutionList"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
              <!-- <button class="btn btn-primary col-12" @click="findRue">
                Buscar
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits } from "vue";
const props = defineProps({
  list: Array,
  selected: Number,
  institution: Object,
  institutionList: Array,
  state: Boolean,
});
const list = computed(() => {
  return props.list;
});

const emit = defineEmits(["selectSchool"]);

let selectItem = ref(props.selected);

function selectSchool() {
  const item = props.institutionList.find(
    (inst) => inst.id === selectItem.value
  );
  emit("selectSchool", item.id);
}
</script>
