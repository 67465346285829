<template>
  <div class="card email-body radius-left">
    <div class="ps-0">
      <div class="tab-content">
        <div
          class="tab-pane active show"
          id="pills-personal"
          role="tabpanel"
          aria-labelledby="pills-personal-tab"
        >
          <div class="card mb-0">
            <div class="card-header container">
              <div class="section"><h5>Estudiantes</h5></div>

              <div class="text-center info-text section">
                <h5>-</h5>
              </div>
              <div class="section" style="text-align: right">
                <button
                  class="btn btn-default"
                  @click="consolidar"
                  :disabled="state"
                >
                  Consolidar
                </button>
              </div>
            </div>
            <div class="card-body">
              <StudentList
                :students="students"
                :codeSie="codeSie"
                :codeLevel="codeLevel"
                :codeCourse="codeCourse"
                :codeGrade="codeGrade"
                :enrolledList="enrolledList"
                @refresh="refresh"
                :state="state"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, defineEmits, inject } from "vue";
import { toast } from "vue3-toastify";
import StudentList from "./StudentList.vue";

const swal = inject("$swal");

const emit = defineEmits(["refresh"]);

const props = defineProps({
  students: Array,
  codeSie: Number,
  codeLevel: Number,
  codeCourse: Number,
  codeGrade: Number,
  enrolledList: Array,
  state: Boolean,
});

const refresh = () => {
  console.log("refresh .. student component");
  emit("refresh");
};

const consolidar = () => {
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Una vez consolidado se concluira el proceso",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        axios
          .get("/structure/close-enrollment/" + props.institution.id)
          .then((response) => {
            toast.success("Pre-Inscripción exitosa!!!");
            emit("refresh");
          })
          .catch(function (error) {
            toast.error("Pre-Inscripción fallida!!!");
          });
      }
    });
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.section {
  flex: 1;
  box-sizing: border-box;
  color: white;
}
</style>
