<template>
  <div class="card mb-0">
    <div class="card-header d-flex">
      <h5>Estudiantes</h5>
      <div class="text-center">
        <button
          class="btn btn-success"
          @click="downloadReport"
          v-if="statusEnrollment"
        >
          Reporte Inscritos
        </button>
      </div>

      <span class="f-14 pull-right mt-0">
        <div v-if="!statusEnrollment">
          <button
            class="btn btn-primary"
            @click="saveInscription"
            :disabled="statusEnrollment"
          >
            Guardar
          </button>
          <button
            class="btn btn-success"
            @click="consolidado"
            :disabled="statusEnrollment"
          >
            Inscribir
          </button>
        </div>
        <div v-else>
          <h6 class="text-success">Inscripción Cerrada</h6>
        </div>
      </span>
    </div>
    <div class="card-body">
      <Students
        :students="students"
        @toggleParticipant="refreshParticipantList"
        :participantSelected="participantSelected"
        :statusEnrollment="statusEnrollment"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, inject, ref, defineEmits } from "vue";
import Students from "./Students.vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";

const swal = inject("$swal");

const emit = defineEmits(["changeStatusEnrollment", "getReport", "reloadList"]);

const props = defineProps({
  students: Array,
  participantList: Array,
  participantSelected: Array,
  statusEnrollment: Boolean,
});

const students = computed(() => {
  return props.students;
});

async function saveInscription() {
  toast.success("Registros Salvados!");
}

function consolidado() {
  // const request = {
  //   institution_id: localStorage.getItem("userId"),
  //   course: 0,
  //   phase: 3,
  // };
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se clasificara el listado de alumnos",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        toast.success("Inscripción exitosa!!!");
      }
    });
}

const participantList = computed(() => {
  return props.participantList;
});

function refreshParticipantList(participant) {
  emit("reloadList");
}

function downloadReport() {
  emit("getReport");
}
</script>
