<template>
  <div class="card mb-0">
    <div class="card-header d-flex">
      <h5>Estudiantes</h5>
      <div class="text-center">
        <p>{{ institution?.institucioneducativa }}</p>
      </div>

      <!-- <span class="f-14 pull-right mt-0">
        <button
          class="btn btn-primary"
          @click="saveInscription"
          :disabled="statusEnrollment"
        >
          Guardar
        </button>
        <button
          class="btn btn-success"
          @click="consolidado"
          :disabled="statusEnrollment"
        >
          Clasificados
        </button>
      </span> -->
      <span class="f-14 pull-right mt-0">
        <!-- <div v-if="!statusEnrollment">
          <button
            class="btn btn-primary"
            @click="saveInscription"
            :disabled="statusEnrollment"
          >
            Guardar
          </button>
          <button
            class="btn btn-success"
            @click="consolidado"
            :disabled="statusEnrollment"
          >
            Inscribir
          </button>
        </div>
        <div v-else> -->
        <h6 class="text-success">Inscripción Cerrada</h6>
        <!-- </div> -->
      </span>
    </div>
    <div class="card-body">
      <Students
        :students="students"
        @toggleParticipant="refreshParticipantList"
        :participantSelected="participantSelected"
        :statusEnrollment="statusEnrollment"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, inject, ref, defineEmits } from "vue";
import Students from "./Students.vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";

const swal = inject("$swal");
const emit = defineEmits(["changeStatusEnrollment"]);

const props = defineProps({
  students: Array,
  institution: Object,
  participantList: Array,
  participantSelected: Array,
  statusEnrollment: Boolean,
});

const infoCourse = computed(() => {
  return props.infoCourse;
});

const students = computed(() => {
  return props.students;
});

const institution = computed(() => {
  return props.institution;
});

async function saveInscription() {
  const currentDate = new Date();
  const dateToCompare = new Date("2024-07-06");

  if (dateToCompare > currentDate) {
    // console.log("savedn 2", participantList.value, institution.value);
    console.log("institution", institution.value);
    // console.log('partit', );
    const request = {
      participants: participantList.value,
      institution_id: institution.value.id,
      level: 0,
      grade: 0,
      parallel: 0,
      course: 0,
      phase: 2,
      userId: localStorage.getItem("userId"),
    };
    await axios
      .post("/enrollment-list", request)
      .then((response) => {
        toast.success("Registros Salvados!");
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    toast.info("Periodo de inscripcion expirado!!!");
  }
}
function consolidado() {
  const request = {
    institution_id: institution.value.id,
    course: 0,
    phase: 2,
  };
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Una vez registrado no se puede modificar el listado de clasificados de la institución",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then(async (result) => {
      if (result.isConfirmed) {
        await saveInscription();
        axios
          .post("/enrollment-list/close-enrollment", request)
          .then((response) => {
            toast.success("Registro exitoso!!!");
            emit("changeStatusEnrollment", true);
          })
          .catch(function (error) {
            toast.error("Registro fallido!!!");
          });
      }
    });
}

const participantList = computed(() => {
  return props.participantList;
});

function refreshParticipantList(participant) {
  const removeItemIndex = participantList.value.indexOf(participant);
  const exist = participantList.value.find((item) => item === participant);
  if (exist) {
    participantList.value.splice(removeItemIndex, 1);
  } else {
    participantList.value.push(participant);
  }
  console.log("list", participantList.value);
}
</script>
