import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";

/* Dashboards */
import Default from "../pages/dashboard/default";
// user
import userProfile from "../pages/users/manage/userProfile.vue";
import userCards from "../pages/users/cards/userCards.vue";
import userEdit from "../pages/users/edit/userEdit.vue";
//events
import registerEvent from "../pages/eventos/registerEvent.vue";
import eventList from "../pages/eventos/projectlist/indexList.vue";
import Enrollment from "@/pages/enrollment/Enrollment.vue";
//config
import Place from "@/pages/config/places/Place.vue";
import KindEvent from "@/pages/config/kind_event/KindEvent.vue";

//inbox
import Inbox from "@/pages/inbox/Inbox.vue";
import History from "@/pages/history/History.vue";

//Olimpiads
import Chess from "@/pages/olympiad/Chess.vue";

//instituciones
import Institution from "@/pages/institution/Institution.vue";

import Distrital from "@/pages/institution_distrital/Institution.vue";
import Departamental from "@/pages/institution_departamental/Institution.vue";
import Nacional from "@/pages/institution_depto/Institution.vue";
import EloDepto from "@/pages/institution_elo/Institution.vue";

//manager
import Tournament from "@/pages/manager/Tournament.vue";
import Category from "@/pages/manager/category/Category.vue";
import Criteria from "@/pages/manager/criteria/Criteria.vue";
import Phase from "@/pages/manager/phase/Phase.vue";
import Phases from "@/pages/manager/phases/Phases.vue";
import Rule from "@/pages/manager/rule/Rule.vue";

//reports
import Report from "@/pages/reports/Report.vue";

/* Auth */
import login from "../auth/login";

//
import register from "@/auth/register.vue";

//--- Scientific Olympiads
import Person from "@/pages/scientific/admin/Person.vue";
import Group from "@/pages/scientific_group/admin/Group.vue";

import PrincipalSingle from "@/pages/scientific/admin/PrincipalSingle.vue";
import PrincipalGroup from "@/pages/scientific_group/admin/PrincipalGroup.vue";

const routes = [
  {
    path: "/",
    component: Body,

    children: [
      {
        path: "",
        name: "defaultRoot",
        component: Default,
        meta: {
          title: "Inicio",
        },
      },
    ],
  },
  {
    path: "/users",
    component: Body,
    children: [
      {
        path: "profile",
        name: "profile",
        component: userProfile,
        meta: {
          title: "Users Admin",
        },
      },
      {
        path: "edit",
        name: "edit",
        component: userEdit,
        meta: {
          title: "Users Admin",
        },
      },
      {
        path: "cards",
        name: "cards",
        component: userCards,
        meta: {
          title: "Users Admin",
        },
      },
    ],
  },
  {
    path: "/events",
    component: Body,
    children: [
      {
        path: "register",
        name: "Registro de Evento",
        component: registerEvent,
        meta: {
          title: "Registro de evento",
        },
      },
      {
        path: "list",
        name: "Lista de Eventos",
        component: eventList,
        meta: {
          title: "Lista de Eventos",
        },
      },
      {
        path: "enrollments",
        name: "Formularios de Inscripción",
        component: Enrollment,
        meta: {
          title: "Formularios de Inscripción",
        },
      },
    ],
  },
  {
    path: "/config",
    component: Body,
    children: [
      {
        path: "places",
        name: "Ambientes",
        component: Place,
        meta: {
          title: "Ambientes",
        },
      },
      {
        path: "kind-event",
        name: "Tipos de evento",
        component: KindEvent,
        meta: {
          title: "Tipos de evento",
        },
      },
    ],
  },
  {
    path: "/inbox",
    component: Body,
    children: [
      {
        path: "request",
        name: "Solicitudes",
        component: Inbox,
        meta: {
          title: "Solicitudes",
        },
      },
      {
        path: "history",
        name: "Historial",
        component: History,
        meta: {
          title: "Historial",
        },
      },
    ],
  },
  {
    path: "/olympiad",
    component: Body,
    children: [
      {
        path: "chess",
        name: "Ajedrez",
        component: Chess,
        meta: {
          title: "Olimpiada de Ajedrez",
        },
      },
    ],
  },
  {
    path: "/scientific",
    component: Body,
    children: [
      {
        path: "person",
        name: "person",
        component: Person,
        meta: {
          title: "Individual",
        },
      },
      {
        path: "group",
        name: "group",
        component: Group,
        meta: {
          title: "Grupal",
        },
      },
      {
        path: "principal-person",
        name: "principal-person",
        component: PrincipalSingle,
        meta: {
          title: "Individual",
        },
      },
      {
        path: "principal-group",
        name: "principal-group",
        component: PrincipalGroup,
        meta: {
          title: "Grupal",
        },
      },
    ],
  },
  {
    path: "/institution",
    component: Body,
    children: [
      {
        path: "enrollment",
        name: "Institucion",
        component: Institution,
        meta: {
          title: "Institucion",
        },
      },
      {
        path: "distrital",
        name: "Distrital",
        component: Distrital,
        meta: {
          title: "Distrital",
        },
      },
      {
        path: "departamental",
        name: "Departamental",
        component: Departamental,
        meta: {
          title: "Departamental",
        },
      },
      {
        path: "elo-validation",
        name: "elo-validation",
        component: EloDepto,
        meta: {
          title: "Participantes ELO",
        },
      },
      {
        path: "nacional",
        name: "Nacional",
        component: Nacional,
        meta: {
          title: "Nacional",
        },
      },
    ],
  },
  {
    path: "/manager",
    component: Body,
    children: [
      {
        path: "tournaments",
        name: "tournaments",
        component: Tournament,
        meta: {
          title: "Gestor Torneos",
        },
      },
      {
        path: "rule",
        name: "rule",
        component: Rule,
        meta: {
          title: "Gestor de Reglas",
        },
      },
      {
        path: "categories/:id",
        name: "categories/:id",
        component: Category,
        meta: {
          title: "Categorías",
        },
      },
      {
        path: "criteria/:id",
        name: "criteria/:id",
        component: Criteria,
        meta: {
          title: "Criterio",
        },
      },
      {
        path: "phase/:id",
        name: "phase/:id",
        component: Phase,
        meta: {
          title: "Fases",
        },
      },
      {
        path: "phases/:id",
        name: "phases/:id",
        component: Phases,
        meta: {
          title: "Fases",
        },
      },
      {
        path: "report",
        name: "report",
        component: Report,
        meta: {
          title: "Reporte",
        },
      },
    ],
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: login,
        meta: {
          title: "Ingresar",
        },
      },
    ],
  },
  {
    path: "/public",
    children: [
      {
        path: "enrollment/:id",
        name: "enrollment/:id",
        component: register,
        meta: {
          title: "Inscripción",
        },
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;
  const path = ["Login 1", "enrollment/:id"];
  if (path.includes(to.name) || localStorage.getItem("User")) {
    return next();
  }
  next("/auth/login");
});
export default router;
