<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Curso</th>
              <th scope="col">RUDE</th>
              <th scope="col">Paterno</th>
              <th scope="col">Materno</th>
              <th scope="col">Nombre</th>
              <th scope="col">Genero</th>
              <th scope="col">Categoria</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in list" :key="item.id">
              <th>{{ key + 1 }}</th>
              <td>{{ gradeDicc(item.grade) }}</td>
              <td>{{ item.codigo_rude }}</td>
              <td>{{ item.paterno }}</td>
              <td>{{ item.materno }}</td>
              <td>{{ item.nombre }}</td>
              <td class="text-center">
                <template v-if="item.genero == 1">
                  <img :src="male" style="width: 40px" />
                </template>
                <template v-else>
                  <img :src="female" style="width: 40px" />
                </template>
              </td>
              <td>{{ item.category }}</td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="selectParticipant(item.id)"
                      :checked="checkStatus(item.id)"
                      :disabled="statusEnrollment"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import axios from "@/includes/connection";

const props = defineProps({
  students: Array,
  statusEnrollment: Boolean,
  participantSelected: Array,
});
const emit = defineEmits(["toggleParticipant"]);

const male = ref(require("@/assets/male.png"));
const female = ref(require("@/assets/female.png"));

const list = computed(() => {
  return props.students;
});

const participantSelected = computed(() => {
  return props.participantSelected;
});

function selectParticipant(participant) {
  emit("toggleParticipant", participant);
  console.log("select", participant);
}

//---
onMounted(() => {
  loadGrades();
});

const listGrades = ref([]);
const listParallel = ref([]);
const listLevel = ref([]);

const gradeDicc = (id) => {
  if (id == 99) {
    return "CEA";
  }
  const item = listGrades.value.find((item) => item.id === id);
  return item?.grado;
};

const levelDicc = (id) => {
  const item = listLevel.value.find((item) => item.id === id);
  return item?.nivel;
};

const parallelDicc = (id) => {
  const item = listParallel.value.find((item) => item.id === id);
  return item?.paralelo;
};

function loadGrades() {
  axios
    .get("/institution/list/grades")
    .then((response) => {
      if (response.data) {
        listGrades.value = response.data;
        console.log("grades", listGrades.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadParallels() {
  axios
    .get("/institution/list/parallels")
    .then((response) => {
      if (response.data) {
        listParallel.value = response.data;
        console.log("parallels", listParallel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadLevels() {
  axios
    .get("/institution/list/levels")
    .then((response) => {
      if (response.data) {
        listLevel.value = response.data;
        console.log("leves", listLevel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function checkStatus(code) {
  const out = participantSelected.value.find(
    (item) => item.participant_id == code
  );
  return out;
}
</script>
