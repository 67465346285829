<template>
  <Breadcrumbs title="Registro Tutores" />

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <div class="col-xl-3 col-md-12 col-12">
          <div>
            <div>
              <!-- <SieFinder @getSie="getSie" :state="finderState" /> -->
              <ChooseInstitution
                :institution="institution"
                :state="finderState"
                @changeState="changeState"
                :selected="codeSie"
                :institutionList="institutionList"
                @selectSchool="getSie"
              />
              <ShowInfo
                :institution="institution"
                :state="infoState"
                @changeState="changeState"
                :mode="mode"
              />
              <ShowGrades
                :state="gradeState"
                :institution="institution"
                :levels="levels"
                :courses="courses"
                @chooseLevel="chooseLevel"
                @chooseCourse="chooseCourse"
                @chooseGrade="chooseGrade"
              />
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-12 box-col-12">
          <StudentPanel
            :students="students"
            :enrolledList="enrolledList"
            :codeSie="codeSie"
            :codeLevel="codeLevel"
            :codeCourse="codeCourse"
            :codeGrade="codeGrade"
            @refresh="getEnrolledCourses"
            :state="state"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from "@/includes/connection";
import { toast } from "vue3-toastify";
import { ref, onMounted } from "vue";

import SieFinder from "../components/SieFinder.vue";
import ShowInfo from "../components/ShowInfo.vue";
import StudentPanel from "../components/StudentPanel.vue";
import ShowGrades from "../components/ShowGrades.vue";
import ChooseInstitution from "../components/ChooseInstitution.vue";

//--states
const finderState = ref(true);
const infoState = ref(false);
const gradeState = ref(false);

//--globals
const codeSie = ref("");
const codeLevel = ref("");
const codeCourse = ref("");
const codeGrade = ref("");

const institution = ref({});
const levels = ref([]);
const courses = ref([]);
const students = ref([]);

const mode = ref(false);

onMounted(() => {
  getListInstitution();
});

const getSie = async (sie) => {
  codeSie.value = sie;
  await getInstitution();
  await getLevels();
};

const chooseLevel = (level) => {
  codeLevel.value = level;
  getCourses();
};

const chooseCourse = (course) => {
  codeCourse.value = course;
  getStudents();
  getEnrolledCourses();
};

const chooseGrade = (grade) => {
  codeGrade.value = grade;
};

const getStudents = async () => {
  await axios
    .get("/structure/institution-course-student/" + codeCourse.value)
    .then((response) => {
      students.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
};

let state = ref(false);
const getStatus = async () => {
  await axios
    .get("/structure/check-status/" + codeSie.value)
    .then((response) => {
      const status = response.data;
      console.log("status", status);
      if (status) {
        state.value = true;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getCourses = async () => {
  await axios
    .get(
      "/structure/institution-grades/" + codeSie.value + "/" + codeLevel.value
    )
    .then((response) => {
      courses.value = response.data;
      console.log("getCourses", courses.value);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const getInstitution = async () => {
  await axios
    .get("/structure/institution-info/" + codeSie.value)
    .then((response) => {
      institution.value = response.data;
      changeState();
    })
    .catch(function (error) {
      toast.error(error.response.data.message);
    });
  console.log("getInstitution");
};

const getLevels = async () => {
  await axios
    .get("/structure/institution-level/" + codeSie.value)
    .then((response) => {
      levels.value = response.data;
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    });
  console.log("getLevels");
};

const enrolledList = ref([]);

const getEnrolledCourses = async () => {
  await axios
    .get("/structure/enrolled-course/" + codeCourse.value)
    .then((response) => {
      enrolledList.value = response.data;
      getStatus();
    })
    .catch(function (error) {
      console.log(error.response.data.message);
    });
  console.log("getEnrolledCourses");
};

const changeState = () => {
  infoState.value = !infoState.value;
  finderState.value = !finderState.value;
  gradeState.value = !gradeState.value;
  students.value = [];
};

const institutionList = ref([]);
const getListInstitution = async () => {
  await axios
    .get("/structure/institution-list/" + localStorage.getItem("username"))
    .then((response) => {
      institutionList.value = response.data;
    })
    .catch(function (error) {
      toast.error(error.response.data.message);
    });
};
</script>
<style></style>
