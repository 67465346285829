<template>
  <div class="container">
    <div class="left-board">
      <div class="logo-wrapper">
        <div class="logo" style="font-size: 14px">
          Parámetros no contemplados
        </div>
      </div>
      <el-scrollbar class="left-scrollbar">
        <page-panel
          @addComponent="
            (el) => {
              settings.drawingList.push(el);
            }
          "
        ></page-panel>

        <div class="components-list"></div>
      </el-scrollbar>
    </div>

    <div class="center-board">
      <input
        type="text"
        class="form-control"
        placeholder="Nombre del formulario"
        v-model="form_name"
      />

      <BaseEnrollment />

      <div class="action-bar">
        <div class="btn" @click="execDownload('test.vue')">
          <el-icon> <video-play /> </el-icon>Registrar
        </div>
        <div class="btn" @click="clearn()">
          <el-icon> <delete /> </el-icon>Limpiar
        </div>
      </div>
      <el-scrollbar class="center-scrollbar" :class="[device, preview]">
        <page-drawer
          :model-value="settings"
          @update:model-value="update"
        ></page-drawer>
      </el-scrollbar>
    </div>

    <page-setting
      :model-value="settings"
      @update:model-value="update"
      style="padding-left: 10px"
    ></page-setting>
  </div>

  <el-drawer v-model="showCode" direction="rtl" size="50%" style="height: 100%">
    <template v-slot:title>
      <div class="preview-action-bar">
        <div class="btn" @click="execDownload('test.vue')">
          <el-icon> <download /> </el-icon>Descargar
        </div>

        <div class="btn" @click="ClipboardWrite()">
          <el-icon> <document-copy /> </el-icon>Copiar
        </div>
      </div>
    </template>
    <el-scrollbar height="600px" v-highlight>
      <pre>
        <code> {{ generate(settings) }}</code>
    </pre>
    </el-scrollbar>
  </el-drawer>
</template>

<script>
import axios from "@/includes/connection";
import { inject } from "vue";
import PageDrawer from "@/form_builder/components/form/PageDrawer.vue";
import PagePanel from "@/form_builder/components/form/PagePanel.vue";
import PageSetting from "@/form_builder/components/form/PageSetting.vue";
import useClipboard from "vue-clipboard3";

import BaseEnrollment from "@/pages/enrollment/components/BaseEnrollment.vue";

import { ElMessage } from "element-plus";

import { saveAs } from "file-saver";

import {
  deepClone,
  findEle,
  isObjectArray,
  randFieldId,
} from "@/form_builder/utils/func.js";
import { formConf } from "@/form_builder/components/form/ui/index";
import { generate, generateJSON } from "@/form_builder/utils/generate.js";
import {
  Iphone,
  Monitor,
  VideoPlay,
  Delete,
  Download,
  DocumentCopy,
} from "@element-plus/icons-vue";
import {
  defineComponent,
  provide,
  reactive,
  ref,
  watch,
  defineEmits,
} from "vue";

export default defineComponent({
  name: "PageGenerator",
  props: {},
  components: {
    PagePanel,
    PageDrawer,
    PageSetting,
    DocumentCopy,
    Iphone,
    Monitor,
    VideoPlay,
    Delete,
    Download,
    BaseEnrollment,
  },

  emits: ["reloadList"],

  setup(props, ctx) {
    const swal = inject("$swal");
    const form_name = ref("");
    const device = ref("pc");
    const showCode = ref(false);
    const preview = ref("");

    // const emit = ["reloadList"]

    const loadSetting = function () {
      let strs = localStorage.getItem("settings");
      if (strs) {
        return JSON.parse(strs);
      }

      return {
        current: "",
        formConf: formConf,
        drawingList: [],
      };
    };
    const settings = reactive(loadSetting());

    const update = function (e) {
      console.log("update element", e);
      settings.formConf = e.formConf;
      settings.drawingList = e.drawingList;
    };

    const selected = function (s) {
      settings.current = s;
    };

    const clearn = function () {
      localStorage.removeItem("settings");

      settings.current = "";
      settings.formConf = formConf;
      settings.drawingList = [];
    };

    /**
     * 选中高亮亮
     */
    const hightLight = function (s) {
      if (settings.current == s) {
        return { border: "1px solid #ffc92fde", backgroundColor: "#e4ff0024" };
      }
      return {};
    };

    const _del = function (items, _id) {
      for (let item of items) {
        if (item.__ID == _id) {
          items.splice(items.indexOf(item), 1);
          return true;
        } else if (isObjectArray(item.childrens)) {
          if (_del(item.childrens, _id)) {
            return true;
          }
        }
      }
    };

    const deleteItem = function (_id) {
      _del(settings.drawingList, _id);
    };

    const _copy = function (items, _id) {
      for (let item of items) {
        if (item.__ID == _id) {
          const newItem = deepClone(item);
          newItem.__ID = randFieldId();
          items.push(reactive(newItem));
          return true;
        } else if (isObjectArray(item.childrens)) {
          if (_copy(item.childrens, _id)) {
            return true;
          }
        }
      }
    };

    const copyItem = function (_id) {
      _copy(settings.drawingList, _id);
    };

    const updateChild = function (eles, ids) {
      let item = findEle(settings.drawingList, ids);

      item.childrens = eles;
    };

    const updateDefaultValue = function (val, ids) {
      let item = findEle(settings.drawingList, ids);

      item.defaultvalue = val;
    };

    const toVal = function (obj) {
      const _c = {};
      for (let a in obj) {
        if (typeof obj[a] == "object") {
          if ("__val__" in obj[a]) {
            _c[a] = obj[a]["__val__"];
          } else {
            _c[a] = toVal(obj[a]);
          }
        } else {
          _c[a] = obj[a];
        }
      }

      return _c;
    };

    const execDownload = function (filename) {
      //---Init transform
      const selout = generateJSON(settings);

      const listItems = selout.childrens;

      let itemPersist = [];

      Object.entries(listItems).filter((el) => {
        let my_child = [];
        if (el[1].hasOwnProperty("childrens")) {
          console.log("has children", el[1].childrens);
          Object.entries(el[1].childrens).filter((child) => {
            let myValue = child[1].attrs;
            console.log("child", myValue);
            let new_child = {
              value: myValue.label,
            };
            my_child.push(new_child);
          });
        }
        let my_element = {
          label: el[1].formItem.label,
          name: el[1].attrs.fieldName,
          as: el[1].tagIcon,
          type: el[1].attrs?.type,
          children: my_child,
          required: true,
        };

        itemPersist.push(my_element);
        console.log("element", el[1]);
      });
      console.log("save", itemPersist);

      console.log("form name", form_name.value.length);
      if (form_name.value.length == 0) {
        swal.fire("Se requiere el nombre del formulario", "", "info");
        return;
      }

      const request = {
        html: JSON.stringify(itemPersist),
        detail: form_name.value,
      };

      axios
        .post("/enrollment-form", request)
        .then((response) => {
          swal.fire({
            title: "Registro Exitoso!!!",
            icon: "success",
          });
          ctx.emit("reloadList");
          form_name.value = "";
          clearn();
        })
        .catch(function (error) {
          console.log("error", error);
          swal.fire({
            title: "Registro fallido!!!",
            icon: "warning",
          });
        });
    };

    const preViewCode = function () {
      showCode.value = true;
    };

    const { toClipboard } = useClipboard();

    const ClipboardWrite = async () => {
      const codeStr = generate(settings);
      try {
        await toClipboard(codeStr);
        ElMessage("Acción Exitosa");
        console.log("Copied to clipboard");
      } catch (e) {
        console.error(e);
      }
    };

    watch(settings, () => {
      console.log(settings);
      localStorage.setItem("settings", JSON.stringify(settings));
    });

    provide("copyItem", copyItem);
    provide("deleteItem", deleteItem);
    provide("selected", selected);
    provide("updateChild", updateChild);
    provide("hightLight", hightLight);
    provide("updateDefaultValue", updateDefaultValue);
    return {
      settings,
      update,
      device,
      clearn,

      preview,
      generate,
      generateJSON,
      execDownload,
      showCode,
      preViewCode,
      ClipboardWrite,
      form_name,
    };
  },
});
</script>

<style lang="scss">
@import "@/form_builder/styles/home";
@import "@/form_builder/styles/index";
</style>
