<template>
  <page-generator device="pc" />
</template>

<script>
import PageGenerator from "@/form_builder/components/form/PageGenerator.vue";

export default {
  components: { PageGenerator },

  setup() {
    return {};
  },
};
</script>

<style></style>
