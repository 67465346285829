<template>
  <div class="default-according style-1 faq-accordion" id="accordionoc4">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h2 class="mb-0">
          <button
            class="btn btn-link btn-block text-start"
            data-bs-target="#collapseicon"
            aria-expanded="true"
            aria-controls="collapseicon"
            v-on:click="toogle = !toogle"
          >
            Formulario Base de inscripción
          </button>
        </h2>
      </div>
      <div
        class="collapse"
        id="collapseicon"
        aria-labelledby="collapseicon"
        data-parent="#accordion"
        :class="{ show: toogle }"
      >
        <div class="card-body socialprofile filter-cards-view">
          <h6>Datos Mínimos</h6>
          <form action="#">
            <div class="row">
              <div class="col-12">
                <div class="mb-12">
                  <label>CI</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Nro de Documento"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Complemento</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Complemento"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <div
                    class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml"
                  >
                    <br />
                    <div class="radio radio-primary">
                      <input
                        id="radioinline1"
                        type="radio"
                        name="radio1"
                        value="option1"
                      />
                      <label class="mb-0" for="radioinline1">Nacional</label>
                    </div>
                    <div class="radio radio-primary">
                      <input
                        id="radioinline2"
                        type="radio"
                        name="radio1"
                        value="option1"
                      />
                      <label class="mb-0" for="radioinline2">Extranjero</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-12">
                  <label>Apellido Paterno</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Apellido Paterno"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Apellido Materno</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Apellido Materno"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Nombre(s)</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Nombre(s)"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-12">
                  <label>Celular/Teléfono</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Celular/Teléfono"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Correo Electrónico</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Correo Electrónico"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-12">
                  <label>Ocupación</label>
                  <select class="form-control" name="title" type="text">
                    <option value="">Seleccione Opción</option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Institución</label>
                  <select class="form-control" name="title" type="text">
                    <option value="">Seleccione Opción</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-12">
                  <label>Cargo</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Cargo"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Fecha de Nacimiento</label>
                  <input
                    class="form-control"
                    name="title"
                    type="date"
                    placeholder="Fecha de Nacimiento"
                  />
                </div>
              </div>
              <div class="col-12">
                <div class="mb-12">
                  <label>Departamento</label>
                  <input
                    class="form-control"
                    name="title"
                    type="text"
                    placeholder="Departamento"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      toogle: false,
    };
  },
};
</script>
