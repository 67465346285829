<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <input
          v-model="searchQuery"
          placeholder="Buscar"
          class="form-control"
        />
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Distrito</th>
              <th scope="col">UE</th>
              <th scope="col">Curso</th>
              <th scope="col">RUDE</th>
              <th scope="col">Paterno</th>
              <th scope="col">Materno</th>
              <th scope="col">Nombre</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in filteredItems" :key="item.id">
              <th>{{ key + 1 }}</th>
              <td>
                {{ item.student_course.course.institution.place.site.lugar }}
              </td>
              <td>
                {{
                  item.student_course.course.institution.institucioneducativa
                }}
              </td>
              <td>
                {{ gradeDicc(item.student_course.course.grado_tipo_id) }}
              </td>
              <td>{{ item.codigo_rude }}</td>
              <td>{{ item.paterno }}</td>
              <td>{{ item.materno }}</td>
              <td>{{ item.nombre }}</td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="selectParticipant(item.id)"
                      :checked="checkStatus(item.id)"
                      :disabled="statusEnrollment"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import axios from "@/includes/connection";

const searchQuery = ref("");

const filteredItems = computed(() => {
  return list.value.filter((item) => {
    return (
      item.student_course.course.institution.place.site.lugar
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()) ||
      item.student_course.course.institution.institucioneducativa
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()) ||
      item.codigo_rude
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()) ||
      item.paterno.toString().includes(searchQuery.value) ||
      item.materno.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.nombre.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  });
});

const props = defineProps({
  students: Array,
  statusEnrollment: Boolean,
  participantSelected: Array,
});
const emit = defineEmits(["toggleParticipant"]);

const list = computed(() => {
  return props.students;
});

const participantSelected = computed(() => {
  return props.participantSelected;
});

function selectParticipant(participant) {
  emit("toggleParticipant", participant);
  console.log("select", participant);
}

//---
onMounted(() => {
  loadGrades();
});

const listGrades = ref([]);
const listParallel = ref([]);
const listLevel = ref([]);

const gradeDicc = (id) => {
  if (id == 99) {
    return "CEA";
  }
  const item = listGrades.value.find((item) => item.id === id);
  return item?.grado;
};

const levelDicc = (id) => {
  const item = listLevel.value.find((item) => item.id === id);
  return item?.nivel;
};

const parallelDicc = (id) => {
  const item = listParallel.value.find((item) => item.id === id);
  return item?.paralelo;
};

function loadGrades() {
  axios
    .get("/institution/list/grades")
    .then((response) => {
      if (response.data) {
        listGrades.value = response.data;
        console.log("grades", listGrades.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadParallels() {
  axios
    .get("/institution/list/parallels")
    .then((response) => {
      if (response.data) {
        listParallel.value = response.data;
        console.log("parallels", listParallel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadLevels() {
  axios
    .get("/institution/list/levels")
    .then((response) => {
      if (response.data) {
        listLevel.value = response.data;
        console.log("leves", listLevel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function checkStatus(code) {
  const out = participantSelected.value.find(
    (item) => item.participant_id == code
  );
  return out;
}
</script>
