<template>
  <div class="col-md-12 project-list">
    <div class="card">
      <div class="row">
        <div class="col-md-6 d-flex"></div>
        <div class="col-md-6">
          <div class="form-group mb-0 me-0"></div>

          <!-- <button
            class="btn btn-primary"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModalCenter"
          >
            Agregar ambiente
          </button> -->

          <ModalCreate />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModalCreate from "./ModalCreate.vue";
export default {
  name: "TopMenu",
  data() {
    return {};
  },
  components: {
    ModalCreate,
  },
  methods: {},
};
</script>
