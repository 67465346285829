<template>
  <div
    id="addModalGroup"
    class="modal fade bd-example-modal-xl"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 style="font-size: 18px !important">Registrar Grupo</h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- <form> -->
          <div class="row">
            <div class="col-12">
              <label>Nombre Grupo</label>
              <input type="text" class="form-control" v-model="group_name" />
            </div>
            <div class="col-12">
              <label>Categorías</label>
              <select
                class="form-control"
                id="categorySelect"
                v-model="group_category"
                @change="updateSubCategories"
              >
                <option value="" disabled>Seleccione opción</option>
                <option
                  v-for="category in myCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </select>
            </div>
            <div class="col-12">
              <label>Sub Categorías</label>
              <select
                id="subcategorySelect"
                v-model="group_project"
                class="form-control"
              >
                <option disabled value="">Seleccione opcion</option>
                <option
                  v-for="subcategory in filteredSubCategories"
                  :key="subcategory.id"
                  :value="subcategory.name"
                >
                  {{ subcategory.name }}
                </option>
              </select>
            </div>

            <div class="col-12">
              <label for="">Buscar Participantes</label>
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Codigo Rude"
                  v-model="code_rude"
                />
                <span class="input-group-text">
                  <button class="btn" @click="findStudent">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
            </div>
            <div class="col-12">
              <div class="input-group mb-3">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Estudiante"
                  :value="studentName"
                  disabled
                />
                <span class="input-group-text">
                  <button class="btn" @click="addStudent">
                    <i class="fa fa-plus"></i>
                  </button>
                </span>
              </div>
            </div>
            <div class="col-12">
              <label for="">Lista Participantes</label>
              <table class="table">
                <tr>
                  <th>#</th>
                  <th>RUDE</th>
                  <th>PATERNO</th>
                  <th>MATERNO</th>
                  <th>NOMBRE(S)</th>
                  <th>Curso</th>
                </tr>
                <tr v-for="(item, index) in student_list" key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.codigo_rude }}</td>
                  <td>{{ item.paterno }}</td>
                  <td>{{ item.materno }}</td>
                  <td>{{ item.nombre }}</td>
                  <td>{{ item.grade }} - {{ item.level }}</td>
                </tr>
              </table>
            </div>
          </div>
          <!-- </form> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="addNewTutor"
            v-if="!enrolled"
          >
            Registrar
          </button>
          <button
            type="button"
            class="btn btn-default"
            @click="closeModal"
            v-if="enrolled"
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  defineProps,
  computed,
  defineEmits,
  getCurrentInstance,
} from "vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";
import Category from "@/pages/manager/category/Category.vue";

const { proxy } = getCurrentInstance();
const formattedDate = computed(() =>
  proxy.$formatDate(props.tutor[0].fecha_nacimiento_tut)
);

const emits = defineEmits(["reload-list", "close-mdl"]);

const props = defineProps({
  student: Object,
  enrolled: Boolean,
  institution: Object,
});

const modeList = ref(true);
const modeAdd = ref(false);
const changeMode = () => {
  modeList.value = !modeList.value;
  modeAdd.value = !modeAdd.value;
};

const idCard = ref("");
const complement = ref("");

const findPerson = () => {
  if (!idCard.value) {
    toast.warning("Ingrese un documento de identidad");
    return;
  }

  //--- check if exist records // document + complement
  if (!false) {
    changeMode();
    return;
  }

  //--- refresh list tutors
  console.log("findPerson", idCard.value);
};

const group_name = ref();
const group_project = ref();
const group_category = ref();
const student_list = ref([]);
const addNewTutor = async () => {
  console.log("addnew", props.institution);
  if (!group_name.value) {
    toast.error("Agregue el nombre del grupo.");
    return;
  }

  if (!group_category.value) {
    toast.error("Agregue categoria.");
    return;
  }

  if (!group_project.value) {
    toast.error("Agregue sub categoria.");
    return;
  }

  if (student_list.value.length < 2) {
    toast.error("Favor seleccionar mas de 1 estudiante");
    return;
  }

  const list = student_list.value.map((item) => item.id);

  console.log("list section:: ", list);

  const request = {
    institution_id: props.institution.id,
    group_name: group_name.value,
    group_project: group_project.value,
    student_list: student_list.value,
    group_category: group_category.value,
    user_id: localStorage.getItem("userId"),
  };

  await axios
    .post("/structure/add-group", request)
    .then((response) => {
      toast.success("Registros Salvado!");
      group_name.value = null;
      group_category.value = null;
      group_project.value = null;
      student_list.value = [];
      emits("close-mdl");
    })
    .catch(function (error) {
      console.log("error ..... ", error.response.data);
      const msgError = error.response.data;
      toast.error("Registro fallido! " + msgError.message);
    });
};

const closeModal = () => {
  console.log("closeModal ... ");
  emits("close-mdl");
};

const student = ref({});
const studentName = ref();
const code_rude = ref();
// ---------------
const findStudent = () => {
  axios
    .get(
      "/structure/find-student/" + code_rude.value + "/" + props.institution.id
    )
    .then((response) => {
      console.log("info response ... ", response.data);
      if (response.data) {
        student.value = response.data;
        studentName.value = `${student.value.paterno} ${student.value.materno} ${student.value.nombre}`;
        console.log("..... ", student.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const addStudent = () => {
  console.log("add student", student.value);
  console.log("student_list", student_list.value);
  if (isEmptyObject(student.value)) {
    toast.error("Debe seleccionar un estudiante.");
    return;
  }

  const exists = student_list.value.some(
    (item) => item.id === student.value.id
  );

  if (exists) {
    toast.error("Estudiante ya registrado en el grupo.");
    return; // Prevent adding the object
  }

  student_list.value.push(student.value);
  student.value = {};
  code_rude.value = "";
  studentName.value = "";
};

const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

//----
const myCategories = ref([
  { id: 1, name: "FERIA CIENTÍFICA" },
  { id: 2, name: "ROBÓTICA" },
]);

const mySubCategories = ref([
  { id: 1, cat: 1, name: "FERIA CIENTÍFICA" },
  { id: 2, cat: 2, name: "Robótica - Constructores - INSECTOS" },
  { id: 3, cat: 2, name: "Robótica - Constructores - Robo Mecanics " },
  { id: 4, cat: 2, name: "Robótica - Constructores - Hardware Libre " },
  {
    id: 5,
    cat: 2,
    name: "Robótica - Hardware Libre - Zumo con Control Remoto (RC)",
  },
  { id: 6, cat: 2, name: "Robótica - Hardware Libre - Zumo Autónomo" },
  { id: 7, cat: 2, name: "Robótica - Hardware Libre - Seguidor de Línea" },
  { id: 8, cat: 2, name: "Robótica - Hardware Libre - Proyectos Robótica " },
]);

const filteredSubCategories = computed(() => {
  if (!group_category.value) return [];
  return mySubCategories.value.filter(
    (sub) => sub.cat === Number(group_category.value)
  );
});
</script>
