<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination justify-content-end pagination-primary">
      <li class="page-item">
        <button
          class="page-link"
          @click="previousPage"
          :disabled="currentPage === 1"
          :class="{ disabled: currentPage === 1 }"
        >
          Anterior
        </button>
      </li>

      <li v-for="page in totalPages" :key="page">
        <button
          class="page-link"
          @click="changePage(page)"
          :disabled="currentPage === page"
          :class="{ active: currentPage === page }"
        >
          {{ page }}
        </button>
      </li>
      <li class="page-item">
        <button
          class="page-link"
          @click="nextPage"
          :disabled="currentPage === totalPages"
          :class="{ disabled: currentPage === totalPages }"
        >
          Siguiente
        </button>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  totalItems: {
    type: Number,
    required: true,
  },
  itemsPerPage: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["page-change"]);

const currentPage = ref(1);

const totalPages = computed(() => {
  return Math.ceil(props.totalItems / props.itemsPerPage);
});

const changePage = (page) => {
  currentPage.value = page;
  emit("page-change", page);
};

const nextPage = () => {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
    emit("page-change", currentPage.value);
  }
};

const previousPage = () => {
  if (currentPage.value > 1) {
    currentPage.value--;
    emit("page-change", currentPage.value);
  }
};
</script>
