<template>
  <div
    class="modal fade"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenter"
    aria-hidden="true"
    :class="hiddenClass"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <vee-form :validation-schema="placeSchema" @submit="register">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Agregar Tipo de Evento</h5>
            <button
              class="btn-close"
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label>Nombre</label>
                  <vee-field
                    class="form-control"
                    name="name"
                    type="text"
                    placeholder="Nombre del ambiente"
                  />
                  <ErrorMessage class="text-danger" name="name" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="mb-3">
                  <label>Detalle</label>
                  <vee-field
                    class="form-control"
                    name="description"
                    type="date"
                    as="textarea"
                    placeholder="Referencia"
                  />
                  <ErrorMessage class="text-danger" name="description" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button class="btn btn-primary" type="submit">Registrar</button>
          </div>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalCreate",
  data() {
    return {
      placeSchema: {
        description: "required",
        name: "required",
      },
    };
  },
  methods: {
    register(values, { resetForm }) {
      this.$swal
        .fire({
          title: "¿Esta seguro?",
          text: "Se registrara el nuevo tipo de evento.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Registrar",
          cancelButtonText: "Cancelar",
        })
        .then((result) => {
          if (result.isConfirmed) {
            console.log("values for place >> ", values);
            this.$axios
              .post("/kind-event", values, {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Headers":
                    "POST, GET, PUT, DELETE, OPTIONS, HEAD, Authorization, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Access-Control-Allow-Origin",
                  "Content-Type": "application/json",
                },
              })
              .then((response) => {
                console.log("response", response);
                resetForm();
                this.$swal
                  .fire({
                    title: "Registro Exitoso!!!",
                    text: "El tipo de evento se registro de manera exitosa",
                    icon: "success",
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      this.closeModal();
                    }
                  });
              })
              .catch(function (error) {
                console.log(error);
                this.$swal.fire({
                  title: "Registro fallido!!!",
                  text: "El ambiente no se registro",
                  icon: "warning",
                });
              });
          }
        });
    },

    closeModal() {
      // Get modal element
      const modal = document.getElementById("exampleModalCenter");
      // Hide the modal using Bootstrap's native JavaScript
      modal.classList.remove("show");
      modal.setAttribute("aria-hidden", "true");
      modal.setAttribute("style", "display: none");

      // Remove modal backdrop
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.parentNode.removeChild(modalBackdrop);
      }
    },
  },
};
</script>
