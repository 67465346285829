<template>
  <button
    class="btn btn-primary"
    type="button"
    @click="formBuilderModal.show()"
  >
    Agregar Formulario de Inscripción
  </button>
  <div
    class="modal fade"
    id="exampleModalCenter"
    ref="modalFormBuilder"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenter"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
      <div class="modal-content" style="height: 85vh" o>
        <div class="modal-body">
          <div
            class="tab-pane fade show active"
            id="timeline"
            role="tabpanel"
            aria-labelledby="timeline"
          >
            <div class="row">
              <page-generator device="pc" @reloadList="closeModal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import PageGenerator from "@/form_builder/components/form/PageGenerator.vue";
import { Modal } from "bootstrap";
import { onMounted, ref, defineEmits } from "vue";

const emit = defineEmits("reloadList");

const modalFormBuilder = ref(null);
let formBuilderModal = null;

onMounted(() => {
  formBuilderModal = new Modal(modalFormBuilder.value);
});

function closeModal() {
  formBuilderModal.hide();
  // emit("reloadList");
}
</script>
