<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">ID</th>
              <th scope="col" class="text-center">Nombre</th>
              <th scope="col" class="text-center">Capacidad</th>
              <th scope="col" class="text-center">Referencia</th>
              <th scope="col" class="text-center">Activo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(place, index) in paginatedItems" :key="place.id">
              <th scope="row">{{ place.id }}</th>
              <td>{{ place.description }}</td>
              <td>{{ place.capacity }}</td>
              <td>{{ place.reference }}</td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="changeState(place.id)"
                      :checked="place.state"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="places.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import axios from "@/includes/connection";
import Pagination from "@/pages/components/Pagination.vue";

let places = ref([]);

onMounted(() => {
  loadItems();
});

function loadItems() {
  axios
    .get("/place")
    .then((response) => {
      if (response.data) {
        places.value = response.data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
function changeState(code) {
  axios
    .patch("/place/" + code)
    .then((response) => {
      console.log("update state", response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return places.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
