<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">ID</th>
              <th scope="col" class="text-center">Evento</th>
              <th scope="col" class="text-center">Detalle</th>
              <th scope="col" class="text-center">Mínimo/Máximo</th>
              <th scope="col" class="text-center">Enviado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ item.title }}</td>
              <td>{{ item.description }}</td>
              <td>{{ item.minimum }}/{{ item.maximum }}</td>
              <td class="text-center">
                <button
                  class="btn btn-primary"
                  @click="changeState(item)"
                  :disabled="item.inbox != 0"
                >
                  <i class="fa fa-send"> Enviar</i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="props.list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import connection from "@/includes/connection";
import { toast } from "vue3-toastify";

const props = defineProps({ list: Array });
const emit = defineEmits(["reloadList"]);
const list = computed(() => {
  return props.list;
});

const changeState = (item) => {
  console.log("item", item);
  connection
    .patch("/event/" + item.id)
    .then((response) => {
      console.log("change state");
      toast.success("Operación exitosa, Evento enviado a comunicación.");
      emit("reloadList");
    })
    .catch(function (error) {
      toast.error("Operación fallida, verifique el estado del evento.");
      console.log("change error", error);
      emit("reloadList");
    });
};

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return props.list.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
