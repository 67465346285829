<template>
  <Breadcrumbs title="Institución" />

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <div class="col-xl-3 col-md-12 col-12">
          <div class="">
            <div class="card">
              <div class="card-body">
                <div class="email-app-sidebar left-bookmark">
                  <div class="media-size-email text-center">
                    <img
                      class="me-3 rounded-circle"
                      src="@/assets/school.png"
                      alt="School"
                      style="width: 60px"
                    />
                  </div>
                  <div class="media">
                    <div class="media-body">
                      <h6 class="f-w-700 text-center">
                        {{ selectInstitution.institucioneducativa }}
                      </h6>
                      <p class="text-center kind-of-institution">
                        {{
                          typeInstitution(
                            selectInstitution.institucioneducativa_tipo_id
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <ul class="nav main-menu contact-options" role="tablist">
                    <newContact
                      :list="listSchool"
                      :selected="0"
                      @selectSchool="changeSchool"
                      @getReport="generateReport"
                    />
                    <li class="nav-item">
                      <span
                        class="main-title"
                        style="text-transform: uppercase"
                      >
                        {{ system }}</span
                      >
                    </li>
                    <template v-if="system == 'Nivel'">
                      <li
                        v-for="grade in levelByInstitution"
                        :key="grade.nivel_tipo_id"
                      >
                        <button
                          class="btn btn-default col-12"
                          type="button"
                          @click="toggleCollapse(grade.nivel_tipo_id)"
                          style="margin-bottom: 5px"
                        >
                          {{ levelDicc(grade.nivel_tipo_id) }}
                        </button>
                        <div v-if="grade.nivel_tipo_id === showItem">
                          <ul>
                            <li
                              v-for="parallel in listParallelByInstitution"
                              :key="parallel.id"
                            >
                              <a @click="selectCourse(parallel)">
                                <span
                                  class="title"
                                  style="padding-left: 10px; padding-top: 5px"
                                >
                                  {{ gradeDicc(parallel.grado_tipo_id) }} -
                                  {{
                                    parallelDicc(parallel.paralelo_tipo_id)
                                  }}</span
                                >
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </template>
                    <template v-if="system == 'sucursales'">
                      <li
                        v-for="branch in branchs"
                        :key="branch.sucursal_tipo_id"
                      >
                        <button
                          class="btn btn-default col-12"
                          type="button"
                          @click="toggleCollapseCea(branch.sucursal_tipo_id)"
                          style="margin-bottom: 5px"
                        >
                          {{ branch.sucursal_tipo_id }}
                        </button>
                        <div v-if="branch.sucursal_tipo_id === showItem">
                          <ul>
                            <li
                              v-for="parallel in listParallelByInstitutionCEA"
                              :key="parallel.id"
                            >
                              <a @click="selectCourse(parallel)">
                                <span
                                  class="title info-branch"
                                  style="padding-left: 10px; padding-top: 5px"
                                >
                                  <p style="font-weight: bold">
                                    {{ turnoDicc(parallel.turno_tipo_id) }}
                                  </p>
                                  <p>{{ parallel.especialidad }}</p>
                                  <p style="font-weight: bold">
                                    {{ parallel.acreditacion }}
                                  </p>
                                  <p>
                                    {{
                                      parallelDicc(parallel.paralelo_tipo_id)
                                    }}
                                  </p>
                                  <p></p>
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </template>
                    <template v-if="system == 'Estudiantes'">
                      <li>
                        <button
                          class="btn btn-default col-12"
                          type="button"
                          @click="loadStudetSpecial"
                          style="margin-bottom: 5px"
                        >
                          Lista
                        </button>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-9 col-md-12 col-12">
          <div class="email-right-aside bookmark-tabcontent contacts-tabs">
            <div class="card email-body radius-left">
              <div class="ps-0">
                <div class="tab-content">
                  <div
                    class="tab-pane fade"
                    v-bind:class="
                      activeclass === 'pills-personal-tab' ? 'active show' : ''
                    "
                    id="pills-personal"
                    role="tabpanel"
                    aria-labelledby="pills-personal-tab"
                  >
                    <personalContact
                      :students="listStudent"
                      :institution="gradeSelected"
                      :infoCourse="infoCourse"
                      :participantList="participantList"
                      :participantSelected="participantSelected"
                      :statusEnrollment="statusEnrollment"
                      :system="system"
                      :departamentSelect="departamentSelect"
                      :systemSelect="systemSelect"
                      @changeStatusEnrollment="changeStatus"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="false">
    <div class="report" ref="reportEnrollment">
      <img src="@/assets/minedu.png" alt="minedu-logo" style="height: 60px" />
      <div class="text-center" style="padding-top: 20px">
        <h5>MINISTERIO DE EDUCACIÓN</h5>

        <h5 v-if="selectInstitution.institucioneducativa_tipo_id == 1">
          VICEMINISTERIO DE EDUCACIÓN REGULAR
        </h5>
        <h5 v-else>VICEMINISTERIO DE EDUCACIÓN ALTERNATIVA Y ESPECIAL</h5>
        <h5>
          REPORTE DE PARTICIPANTES CLASIFICADOS A LA 2DA. OLIMPIADA DE AJEDREZ
          DEL SISTEMA EDUCATIVO PLURINACIONAL - GESTIÓN 2024
        </h5>
      </div>
      <div style="padding-top: 10px">
        <table class="info" width="100%">
          <tr>
            <td width="50%">
              DEPARTAMENTO: <b>{{ departamentName }}</b>
            </td>
            <td width="50%">
              U.E./CEA/CEE: <b>{{ selectInstitution.institucioneducativa }}</b>
            </td>
          </tr>
          <tr>
            <td>
              DISTRITO: <b>{{ distritName }}</b>
            </td>
            <td>GESTIÓN: <b>2024</b></td>
          </tr>
        </table>
      </div>
      <div style="padding-top: 15px">
        <table class="content" border="1" width="100%">
          <tr>
            <th>N°</th>
            <th>RUDEAL/RUDE/RUDEES</th>
            <th>CARNET</th>
            <th>NOMBRES Y APELLIDOS</th>
            <th>GENERO</th>
            <th>CATEGORÍA</th>
          </tr>
          <tr
            v-for="(participant, index) in participantEnrolled"
            key="participant.carnet"
            class="no-page-break"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ participant.rude }}</td>
            <td>{{ participant.carnet }}</td>
            <td>{{ participant.participante }}</td>
            <td>{{ participant.genero }}</td>
            <td>{{ participant.categoria }}</td>
          </tr>
        </table>
        <p style="text-align: justify; font-size: 10px">
          El presente documento, constituye una Declaración Jurada sobre los
          datos del estudiante/participante clasificados de la primera FASE.
        </p>
      </div>
      <div
        style="padding-top: 10px"
        v-if="selectInstitution.institucioneducativa_tipo_id == 1"
        class="no-page-break"
      >
        <h6>Educación Primaria Comunitaria Vocacional</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td rowspan="2">Pri1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
            <tr>
              <td rowspan="2">Pri2</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        style="padding-top: 10px"
        v-if="selectInstitution.institucioneducativa_tipo_id == 1"
        class="no-page-break"
      >
        <h6>Educación Secundaria Comunitaria Productiva</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td rowspan="2">Sec1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
            <tr>
              <td rowspan="2">Sec2</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        style="padding-top: 10px"
        v-if="selectInstitution.institucioneducativa_tipo_id == 4"
        class="no-page-break"
      >
        <h6>Estudiantes con discapacidad visual y auditiva de CEE</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td rowspan="2">EspAud1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
            <tr>
              <td rowspan="2">EspVis2</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
          </table>
        </div>
      </div>
      <div
        style="padding-top: 10px"
        v-if="selectInstitution.institucioneducativa_tipo_id == 2"
        class="no-page-break"
      >
        <h6>Estudiantes de Centros de Educación Alternativa</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td rowspan="2">EPJA DAMAS - VARONES (Mixto)</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td align="left">2</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="padding-top: 100px" class="no-page-break">
        <table width="100%">
          <tr>
            <td width="30%"></td>
            <td width="40%">
              <div class="text-center">
                <p class="signature-line">Sello y Firma del Director/a</p>
              </div>
            </td>
            <td width="30%"></td>
          </tr>
        </table>
      </div>
      <div style="padding-top: 80px" class="no-page-break">
        <p style="font-style: italic; font-size: 10px; text-align: justify">
          <b>Nota:</b>*El registro de los nombres de las y los
          estudiantes/participantes clasificados debe realizarse sin tener
          sobrescritos.
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.no-page-break {
  page-break-inside: avoid;
}
h5 {
  font-size: 12px !important;
  padding: 0 !important;
  margin: 0 !important;
}
h6 {
  font-size: 11px !important;
}
.content {
  width: 100%;
  border-collapse: collapse;
}
.content th {
  font-size: 11px;
  border: 1px solid;
  padding: 0px 10px;
}
.content td {
  font-size: 10px;
  border: 1px solid;
  padding: 0px 10px;
}
.signature-line {
  border-top: 1px dotted black;
  padding-top: 5px;
  font-size: 11px;
}

.info td {
  font-size: 11px;
}
</style>

<script setup>
import newContact from "./newContact.vue";
import personalContact from "./personalContact.vue";
import { onMounted, ref, computed } from "vue";
import axios from "@/includes/connection";
import { jsPDF } from "jspdf";

import html2pdf from "html2pdf.js";

const reportEnrollment = ref(null);

import { toast } from "vue3-toastify";

const nodes = ref([]);

const system = ref("");

const typeInstitution = (id) => {
  console.log("typeinstitutio", id);
  console.log("listtypes", listTypes.value);
  const item = listTypes.value.find((item) => item.id === id);
  let mode = "NN";
  if (item?.id === 1) {
    mode = "UE";
    system.value = "Nivel";
  }
  if (item?.id === 2) {
    mode = "CEA";
    system.value = "sucursales";
  }

  if (item?.id === 4) {
    mode = "CEE";
    system.value = "Estudiantes";
  }
  return item?.descripcion + "/" + mode;
};

const gradeDicc = (id) => {
  const item = listGrades.value.find((item) => item.id === id);
  return item?.grado;
};

const levelDicc = (id) => {
  const item = listLevel.value.find((item) => item.id === id);
  return item?.nivel;
};

const parallelDicc = (id) => {
  // console.log("paralelo dictionay", id);
  const item = listParallel.value.find((item) => item.id == id);
  return item?.paralelo;
};

const turnoDicc = (id) => {
  // console.log("paralelo dictionay", id);
  const turnos = [
    { id: 0, turno: "SIN DEFINIR" },
    { id: 1, turno: "MAÑANA" },
    { id: 2, turno: "TARDE" },
    { id: 4, turno: "NOCHE" },
    { id: 8, turno: "MAÑANA TARDE" },
    { id: 9, turno: "TARDE NOCHE" },
    { id: 10, turno: "MAÑANA, TARDE Y NOCHE" },
    { id: 11, turno: "MAÑANA-NOCHE" },
  ];
  const item = turnos.find((item) => item.id == id);
  return item?.turno;
};

const activeclass = ref("pills-personal-tab");
const filtered = ref(false);

function active(item) {
  activeclass = item;
}

function collapseFilter() {
  filtered = !filtered;
}

const listInstitutions = ref([]);
const institution = ref();
const defaultSchool = ref();
let institutionName = ref();
const listSchool = ref([]);
let selectInstitution = ref({});
let gradeSelected = ref({});

const listGrades = ref([]);
const listParallel = ref([]);
const listLevel = ref([]);
const listTypes = ref([]);

let infoCourse = ref({
  level: "",
  course: "",
  parallel: "",
});

function loadInstitutions() {
  if (localStorage.getItem("type") != 2) {
    toast.error("Perfil no valido!");
    return;
  }
  axios
    .get("/institution/" + localStorage.getItem("username")) // //3114877 --- 3351658
    .then((response) => {
      if (response.data) {
        listInstitutions.value = response.data;

        console.log("data response", response.data[0]);
        // defName = response.data
        if (listInstitutions.value) {
          institution.value = listInstitutions.value[0];
          const hasInstitution = institution.value.person_institution;
          listSchool.value = hasInstitution;
          if (hasInstitution) {
            defaultSchool.value = hasInstitution[0];
            institutionName.value =
              defaultSchool.value.institution.institucioneducativa;
            selectInstitution.value = defaultSchool.value.institution;
            //check if is sea
            console.log("check if is sea", selectInstitution.value);
          }
        }
        //check if a sea
        loadBranchsByInstitution(selectInstitution.value.id);
        // if(){}
        console.log("selectInstitution", selectInstitution.value);
        loadLevelsByInstitution();
        getDepartament();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

const scheduleByBranch = ref([]);
const branchs = ref([]);
function loadBranchsByInstitution(id) {
  axios
    .get("/institution/list/branchs/" + id)
    .then((response) => {
      if (response.data) {
        branchs.value = response.data;
        console.log("branchs", branchs.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadGrades() {
  axios
    .get("/institution/list/grades")
    .then((response) => {
      if (response.data) {
        listGrades.value = response.data;
        console.log("grades", listGrades.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

const levelByInstitution = ref([]);

function loadLevelsByInstitution() {
  console.log("load by institution ", selectInstitution.value.id);
  axios
    .get("/institution/list/courses/" + selectInstitution.value.id)
    .then((response) => {
      if (response.data) {
        levelByInstitution.value = response.data;
        console.log("levelsByInstitution", levelByInstitution.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
function loadParallels() {
  axios
    .get("/institution/list/parallels")
    .then((response) => {
      if (response.data) {
        listParallel.value = response.data;
        console.log("parallels", listParallel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadLevels() {
  axios
    .get("/institution/list/levels")
    .then((response) => {
      if (response.data) {
        listLevel.value = response.data;
        console.log("leves", listLevel.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadTypes() {
  axios
    .get("/institution/list/types")
    .then((response) => {
      if (response.data) {
        listTypes.value = response.data;
        console.log("types", listTypes.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

let listStudent = ref([]);
const participantList = ref([]);
function selectCourse(grade) {
  console.log("chose one", grade);
  participantList.value = [];
  getParticipantSelected(
    grade.institucioneducativa_id,
    grade.nivel_tipo_id,
    grade.grado_tipo_id,
    grade.id
  );
  getStatusEnrollment(
    grade.institucioneducativa_id,
    grade.nivel_tipo_id,
    grade.grado_tipo_id,
    grade.paralelo_tipo_id,
    grade.id
  );

  infoCourse.value = {
    level: levelDicc(grade.nivel_tipo_id),
    course: gradeDicc(grade.grado_tipo_id),
    parallel: parallelDicc(grade.paralelo_tipo_id),
  };

  console.log("chose additional", infoCourse.value);
  gradeSelected.value = grade;
  axios
    .get("/institution/students/" + grade.id)
    .then((response) => {
      if (response.data) {
        listStudent.value = response.data[0].students;
        console.log("data student change", listStudent.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

function loadStudetSpecial() {
  console.log("chose special one", selectInstitution.value.id);

  gradeSelected.value = {
    ciclo_tipo_id: 100,
    gestion_tipo_id: 2024,
    grado_tipo_id: 100,
    id: 100,
    institucioneducativa_id: selectInstitution.value.id,
    nivel_tipo_id: 100,
    paralelo_tipo_id: 100,
    periodo_tipo_id: 100,
    sucursal_tipo_id: 100,
    superior_institucioneducativa_periodo_id: null,
    turno_tipo_id: 100,
  };

  participantList.value = [];
  getParticipantSelected(selectInstitution.value.id, 100, 100, 100);
  getStatusEnrollment(selectInstitution.value.id, 100, 100, 100, 100);

  infoCourse.value = {
    level: "",
    course: "",
    parallel: "",
  };

  axios
    .get("/institution/students/all/" + selectInstitution.value.id)
    .then((response) => {
      console.log("load special", response.data);
      listStudent.value = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

const statusEnrollment = ref();
function getStatusEnrollment(ins, level, grade, parallel, course) {
  axios
    .get(
      "/enrollment-list/enrolled/" +
        ins +
        "/" +
        level +
        "/" +
        grade +
        "/" +
        parallel +
        "/" +
        course
    )
    .then((response) => {
      console.log("institution", ins);
      console.log("level", level);
      console.log("grade", grade);
      console.log("parallel", parallel);
      console.log("getEnrolledStatus", response.data.state);
      statusEnrollment.value = response.data.state;
    })
    .catch(function (error) {
      console.log(error);
    });
}

const participantSelected = ref([]);
function getParticipantSelected(ins, level, grade, course) {
  axios
    .get(
      "/enrollment-list/saved/" + ins + "/" + level + "/" + grade + "/" + course
    )
    .then((response) => {
      console.log("getParticipantSelected", response.data);
      participantSelected.value = response.data;

      participantList.value = participantSelected.value.map((el) => {
        return el.participant_id;
      });
      console.log("list", participantList.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}
const departamentSelect = ref(0);
const systemSelect = ref(0);
function changeSchool(inst) {
  console.log("change school", inst.institution);
  selectInstitution.value = inst.institution;
  loadLevelsByInstitution();
  getDepartament();
}

function getDepartament() {
  const inst = selectInstitution.value.id;
  axios
    .get("/institution/locale/" + inst)
    .then((response) => {
      console.log("get departament", response.data);
      if (response.data.length) {
        departamentSelect.value = response.data[0].id_lugar_tipo_depto;
        systemSelect.value = response.data[0].system;
        console.log("get departament selected code", departamentSelect.value);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  getDistrit();
}

const distritId = ref(0);
function getDistrit() {
  const inst = selectInstitution.value.id;
  axios
    .get("/institution/get-place/distrit/" + inst)
    .then((response) => {
      console.log("get distrit", response.data);
      if (response.data.length) {
        distritId.value = response.data[0].lugar_tipo_id_distrito;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

// function loadLevelsByInstitution() {}

let listParallelByInstitution = ref([]);
let showItem = ref();
function toggleCollapse(index) {
  // this.items[index].show = !this.items[index].show;
  console.log("show", index);
  showItem.value = index;

  // console.log("load by institution ", selectInstitution.value.id);
  axios
    .get(
      "/institution/list/parallel/" +
        selectInstitution.value.id +
        "/" +
        showItem.value
    )
    .then((response) => {
      if (response.data) {
        listParallelByInstitution.value = response.data;
        console.log(
          "listParallelByInstitution",
          listParallelByInstitution.value
        );
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

let listParallelByInstitutionCEA = ref([]);
function toggleCollapseCea(index) {
  console.log("show cea horario", index);
  showItem.value = index;

  console.log("load by institution ", selectInstitution.value.id);
  axios
    .get(
      "/institution/list/parallel/cea/" +
        selectInstitution.value.id +
        "/" +
        showItem.value
    )
    .then((response) => {
      console.log("load cea courses", response.data);
      if (response.data) {
        listParallelByInstitutionCEA.value = response.data;
        console.log(
          "listParallelByInstitutionCEA",
          listParallelByInstitutionCEA.value
        );
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

onMounted(() => {
  nodes.value = [
    {
      id: 1,
      label: "demo",
      data: "polar",
      children: [
        {
          label: "polar",
          data: "demo",
        },
      ],
    },
    {
      id: 2,
      label: "demo2",
      data: "polar2",
      children: [
        {
          label: "polar3",
          data: "demo3",
        },
      ],
    },
  ];
  loadInstitutions();
  loadGrades();
  loadParallels();
  loadLevels();
  loadTypes();
});

function handleNodeSelect(node) {
  console.log("selectedNode", node);
}

function changeStatus(status) {
  statusEnrollment.value = status;
  console.log("status", status);
}

const logo = ref(require("@/assets/cup.png"));

const participantEnrolled = ref();

const departamentName = ref();
const distritName = ref();

async function getEnrolledList() {
  const inst = selectInstitution.value.id;
  await axios
    .get("/institution/place/name/" + departamentSelect.value)
    .then((response) => {
      departamentName.value = response.data[0].lugar;
    })
    .catch(function (error) {
      console.log(error);
    });

  await axios
    .get("/institution/place/name/" + distritId.value)
    .then((response) => {
      distritName.value = response.data[0].lugar;
    })
    .catch(function (error) {
      console.log(error);
    });

  await axios
    .get("/institution/list-student/enrolled/" + inst)
    .then((response) => {
      participantEnrolled.value = response.data;
      console.log("participantEnrolled", participantEnrolled.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const generateReport = async () => {
  console.log("generate report for", selectInstitution.value);
  await getEnrolledList();
  console.log("generate report after load data");
  html2pdf(reportEnrollment.value, {
    margin: 1,
    filename: selectInstitution.value.id + ".pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  }).from(() => {
    const pdf = document.querySelector(".html2pdf"); // Select the generated PDF element
    const pdfBlob = pdf2blob(pdf); // Convert the PDF element to a Blob
    const pdfUrl = URL.createObjectURL(pdfBlob); // Create a URL for the Blob
    const downloadLink = document.createElement("a"); // Create a download link element
    downloadLink.href = pdfUrl;
    downloadLink.download = "report.pdf"; // Set the filename for download
    downloadLink.click(); // Simulate a click event to trigger the download
  });
};

function pdf2blob(pdf) {
  const pdfData = new Blob([pdf.outerHTML], { type: "application/pdf" });
  return pdfData;
}
</script>
