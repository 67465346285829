<template>
    <Breadcrumbs title="Roles" main="Users" />
    <div class="container-fluid">
        <div class="edit-profile">
            <div class="row">
                <!-- <sidePage />
                <editProfile /> -->
                <projects />
                <!-- #todo
                1. list all roles
                2. create a new roles
                3. edit a role
                4. remove a role  -->
                
            </div>
        </div>
</div>
</template>
<script>
import sidePage from './sidePage.vue';
import projects from './projects.vue'
import editProfile from "./editProfile.vue"
export default {
    components: {
        sidePage,
        projects,
        editProfile
    }
}
</script>