<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div class="login-main">
                <div class="text-center">
                  <img
                    class="img-fluid"
                    src="../assets/minedu.png"
                    alt="loginpage"
                    style="width: 280px"
                  />
                </div>

                <vee-form
                  :validation-schema="baseFormSchema"
                  @submit="login"
                  class="theme-form"
                >
                  <h4 style="text-align: center; padding: 10px 0">
                    Eventos - Torneos
                  </h4>
                  <div class="text-center">
                    <img
                      class="img-fluid"
                      :src="icon"
                      alt="loginpage"
                      style="width: 100px"
                    />
                  </div>

                  <div class="form-group">
                    <label class="col-form-label glabel">Usuario</label>
                    <vee-field
                      class="form-control"
                      name="username"
                      type="text"
                      placeholder="Usuario"
                    />
                    <ErrorMessage class="text-danger" name="username" />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label glabel">Contraseña</label>
                    <vee-field
                      class="form-control"
                      name="password"
                      type="password"
                      placeholder="Contraseña"
                    />
                    <ErrorMessage class="text-danger" name="password" />
                  </div>
                  <div class="form-group">
                    <label class="col-form-label glabel">Institución</label>
                    <vee-field
                      as="select"
                      name="type"
                      class="form-control"
                      v-model="kind"
                      @change="changeMode"
                    >
                      <option value="1" selected>
                        Ministerio de Educación
                      </option>
                      <option value="2">SIE</option>
                    </vee-field>
                    <ErrorMessage class="text-danger" name="type" />
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <div class="text-end mt-3">
                        <button
                          class="btn btn-success btn-block w-100"
                          type="submit"
                        >
                          Ingresar
                        </button>
                      </div>
                    </div>
                  </div>
                </vee-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { toast } from "vue3-toastify";
import axios from "@/includes/connection";
import { useRouter } from "vue-router";

const router = useRouter();

const kind = ref(2);
const icon = ref(require("@/assets/cup.png"));

const baseFormSchema = {
  username: "required",
  password: "required",
  type: "required",
};

function login(values, { resetForm }) {
  console.log("payload 1", values);
  axios
    .post("/user/login", values)
    .then((response) => {
      console.log("response login", response.data);
      const profile = response.data.user.external_id;
      if (response.data.token) {
        const fullName =
          response.data.user.name + " " + response.data.user.first_last_name;
        localStorage.setItem(
          "User",
          JSON.stringify({ email: fullName, useer: true })
        );
        if (values.type == 2) {
          console.log("profile", profile);
          localStorage.setItem("profile", profile);
        } else {
          const profileMinedu = response.data.user.external_source_id;
          console.log("profile minedu", response.data);
          localStorage.setItem("profile", profileMinedu);
        }
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("type", values.type);
        localStorage.setItem("username", response.data.userId);
        localStorage.setItem("userId", response.data.user.id);
        localStorage.setItem("place", response.data.place);
        // router.push("/");
        console.log("section to redirect");
        window.location.href = "/";
      } else {
        toast.error("Credenciales no validas!");
      }
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Credenciales no validas!");
    });
}
function changeMode() {
  if (kind.value == 1) {
    icon.value = require("@/assets/medalla.png");
  } else {
    icon.value = require("@/assets/cup.png");
  }
}
</script>

<style>
.login-card {
  background: linear-gradient(to right, #000a23, #557996) !important;
}

.glabel {
  font-weight: bold;
}
</style>
