<template>
  <button class="btn btn-primary" type="button" @click="createModal.show()">
    <i class="fa fa-plus"> </i>
  </button>

  <div
    class="modal fade"
    ref="eventModal"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenter"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <vee-form :validation-schema="placeSchema" @submit="registerPlace">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crear Torneo</h5>
            <button
              class="btn-close"
              type="button"
              @click="createModal.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label>Nombre del torneo</label>
                  <vee-field
                    class="form-control"
                    name="description"
                    type="text"
                  />
                  <ErrorMessage class="text-danger" name="description" />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <div class="mb-3">
                  <label>Modalidad</label>
                  <vee-field as="select" name="mode_id" class="form-select">
                    <option selected disabled>Seleccione una opción</option>
                    <option
                      v-for="kind in kinds"
                      :value="kind.id"
                      :key="kind.id"
                    >
                      {{ kind.name }}
                    </option>
                  </vee-field>

                  <ErrorMessage class="text-danger" name="mode_id" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button class="btn btn-primary" type="submit">Registrar</button>
          </div>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, defineEmits } from "vue";
import axios from "@/includes/connection";
import { Modal } from "bootstrap";

const swal = inject("$swal");
const createEventModal = ref(null);
const emits = defineEmits(["reload-list"]);

function registerPlace(values, { resetForm }) {
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se registrara el nuevo torneo?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Registrar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        console.log("values for place >> ", values);
        axios
          .post("/tournament", values)
          .then((response) => {
            console.log("response", response);
            resetForm();
            swal
              .fire({
                title: "Registro Exitoso!!!",
                text: "El torneo se registro de manera exitosa",
                icon: "success",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  emits("reload-list");
                  createModal.hide();
                }
              });
          })
          .catch(function (error) {
            console.log(error);
            swal.fire({
              title: "Registro fallido!!!",
              text: "No se pudo crear el torneo",
              icon: "warning",
            });
          });
      }
    });
}

const eventModal = ref(null);
let createModal = null;
onMounted(() => {
  createModal = new Modal(eventModal.value);
  loadkindEvent();
});

const placeSchema = {
  description: "required",
  mode_id: "required",
};

const kinds = ref([]);
function loadkindEvent() {
  axios
    .get("/kind-event")
    .then((response) => {
      console.log("list kind", response.data);
      if (response.data) {
        kinds.value = response.data.filter((kind) => {
          return kind.state;
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
