<template>
  <vee-form :validation-schema="baseFormSchema" @submit="send">
    <div class="card-header" style="padding: 0">
      <h6 class="text-center">Formulario de Registro de Eventos</h6>
    </div>
    <div class="card-body">
      <div class="form theme-form">
        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Titulo del Evento</label>
              <vee-field
                class="form-control"
                name="title"
                type="text"
                placeholder="Titulo del Evento"
              />
              <ErrorMessage class="text-danger" name="title" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="mb-3">
              <label>Descripción del Evento</label>
              <vee-field
                class="form-control"
                name="description"
                type="text"
                placeholder="Titulo del Evento"
              />
              <ErrorMessage class="text-danger" name="description" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <div class="mb-3">
              <label>Fecha inicio</label>
              <vee-field
                class="form-control"
                name="init_date"
                type="date"
                placeholder="Titulo del Evento"
                @change="calculateDuration()"
                v-model="init_date"
              />
              <ErrorMessage class="text-danger" name="init_date" />
            </div>
          </div>
          <div class="col-sm-5">
            <div class="mb-3">
              <label>Fecha fin</label>
              <vee-field
                class="form-control"
                name="end_date"
                type="date"
                placeholder="Titulo del Evento"
                @change="calculateDuration()"
                v-model="end_date"
              />
              <ErrorMessage class="text-danger" name="end_date" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="mb-3">
              <label>Dias</label>
              <vee-field
                class="form-control"
                name="duration"
                type="number"
                value="0"
                readonly
                v-model="duration"
              />
              <ErrorMessage class="text-danger" name="duration" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="mb-3">
              <label>Tipo</label>
              <vee-field as="select" name="kind_event_code" class="form-select">
                <option selected disabled>Seleccione una opción</option>
                <option v-for="kind in kinds" :value="kind.id" :key="kind.id">
                  {{ kind.name }}
                </option>
              </vee-field>
              <ErrorMessage class="text-danger" name="kind_event_code" />
            </div>
          </div>
          <div class="col-sm-6">
            <div class="mb-3">
              <label>Lugar del Evento</label>
              <div class="input-group">
                <vee-field class="form-select" name="place_code" as="select">
                  <option selected disabled>Seleccione una opción</option>
                  <option
                    v-for="place in places"
                    :value="place.id"
                    :key="place.id"
                  >
                    {{ place.description }}
                  </option>
                </vee-field>

                <ModalCreate @reload-list-place="loadPlaces" />
              </div>
              <ErrorMessage class="text-danger" name="place_code" />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-5">
            <div class="mb-3">
              <label>Cantidad Minima</label>
              <vee-field
                class="form-control"
                name="minimum"
                type="number"
                placeholder="Cantidad Minima"
              />
              <ErrorMessage class="text-danger" name="minimum" />
            </div>
          </div>
          <div class="col-sm-5">
            <div class="mb-3">
              <label>Cantidad Maxima</label>
              <vee-field
                class="form-control"
                name="maximum"
                type="number"
                placeholder="Cantidad Maxima"
              />
              <ErrorMessage class="text-danger" name="maximum" />
            </div>
          </div>

          <div class="col-sm-2">
            <div class="mb-3">
              <label class="col-form-label m-r-10">Sin Parámetro</label>
              <div class="media-body text-center icon-state switch-outline">
                <label class="switch">
                  <vee-field name="with_limit_control" type="checkbox" />
                  <span class="switch-state bg-primary inactive"></span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-6">
            <div class="mb-3">
              <label
                >Detalle y/o observaciones (Aclarar si se requiere Banner y
                Afiche)</label
              >
              <vee-field
                as="textarea"
                name="detail"
                class="form-control"
                rows="3"
              ></vee-field>
              <ErrorMessage class="text-danger" name="detail" />
            </div>
          </div>
          <div class="col-sm-2">
            <label>Subir Banner</label>
            <vee-field class="form-control" name="banner" type="file" />
            <ErrorMessage class="text-danger" name="banner" />
          </div>
          <div class="col-sm-2">
            <label>Subir Afiche</label>
            <vee-field class="form-control" name="poster" type="file" />
            <ErrorMessage class="text-danger" name="poster" />
          </div>
          <div class="col-sm-2">
            <label>Subir Reglamento</label>
            <vee-field class="form-control" name="regulation" type="file" />
            <ErrorMessage class="text-danger" name="regulation" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6">
        <div class="mb-3">
          <label>Formulario de Inscripción</label>
          <vee-field as="select" name="enrollment" class="form-select">
            <option selected disabled>Seleccione una opción</option>
            <option
              v-for="item in listEnrollment"
              :value="item.id"
              :key="item.id"
            >
              {{ item.detail }}
            </option>
          </vee-field>
          <ErrorMessage class="text-danger" name="enrollment" />
        </div>
      </div>
    </div>

    <div class="card-footer" style="padding: 0">
      <br />
      <div class="text-right">
        <div class="mb-3 row">
          <div class="col-lg-12" style="text-align: right">
            <button
              id="register-event"
              name="singlebutton"
              class="btn btn-primary"
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </vee-form>
</template>

<script setup>
import { ref, onMounted, inject, watch, defineEmits } from "vue";
import ModalCreate from "@/pages/config/places/components/ModalCreate.vue";
import axios from "@/includes/connection";
import fileSender from "@/includes/fileconnection";
import { Modal } from "bootstrap";

const swal = inject("$swal");
const createEventModal = ref(null);
const emit = defineEmits(["reloadEvents"]);

onMounted(() => {
  loadPlaces();
  loadkindEvent();
  loadListEnrollment();
});

const baseFormSchema = {
  title: "required",
  init_date: "required",
  end_date: "required",
  duration: "",
  place_code: "required",
  kind_event_code: "required",
  minimum: "required",
  maximum: "required",
  detail: "required",
  banner: "image|size:1024",
  poster: "image|size:1024",
  regulation: "required|mimes:application/pdf|size:1024",
  enrollment: "required",
  description: "required",
};

const end_date = ref("");
const init_date = ref("");
const duration = ref(0);

function calculateDuration() {
  if (init_date.value && end_date.value) {
    const date1 = new Date(init_date.value);
    const date2 = new Date(end_date.value);
    let diffDays = date2.getDate() - date1.getDate();
    duration.value = diffDays;
  }
}

function send(values, { resetForm }) {
  console.log(JSON.stringify(values, null, 2));
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se registrara un nuevo evento.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Registrar Evento",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();

        Object.entries(values).forEach(([key, value]) => {
          console.log("key", key, value);
          formData.append(key, value);
        });

        fileSender
          .post("/event", formData)
          .then((response) => {
            console.log("response", "");
            resetForm();
            swal.fire({
              title: "Registro Exitoso!!!",
              text: "El evento se registro de manera exitosa",
              icon: "success",
            });
            emit("reloadEvents");
          })
          .catch(function (error) {
            swal.fire({
              title: "Registro fallido!!!",
              text: "El evento no se registro de manera exitosa",
              icon: "warning",
            });
          });
      }
    });
}

const places = ref([]);
function loadPlaces() {
  axios
    .get("/place")
    .then((response) => {
      console.log("list places ", response.data);
      if (response.data) {
        places.value = response.data.filter((place) => {
          return place.state;
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

const kinds = ref([]);
function loadkindEvent() {
  axios
    .get("/kind-event")
    .then((response) => {
      console.log("list kind", response.data);
      if (response.data) {
        kinds.value = response.data.filter((kind) => {
          return kind.state;
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}

const listEnrollment = ref([]);
function loadListEnrollment() {
  axios
    .get("/enrollment-form")
    .then((response) => {
      if (response.data) {
        listEnrollment.value = response.data.filter((item) => {
          return item.state;
        });
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
