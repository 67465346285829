<template>
  <!-- {{currentItem}} -->
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane label="Propiedades" name="field">
        <div class="field-box">
          <el-scrollbar class="right-scrollbar">
            <template v-if="currentItem">
              <el-form
                ref="form2"
                size="mini"
                label-position="top"
                label-width="80px"
              >
                <el-form-item
                  v-for="(func, title) in currentItem.actions"
                  :key="title"
                >
                  <el-button @click="func(currentItem)">{{ title }}</el-button>
                </el-form-item>
                <template
                  v-for="(item, index) in eleRenderSetFormat(currentItem)"
                  :key="index + '-' + currentItem.__ID"
                >
                  <element-render
                    @update="updateEleSet"
                    v-bind="item"
                  ></element-render>
                </template>
              </el-form>

              <el-divider>Validacion</el-divider>
              <rules-input
                :openRule="currentItem.__openRules"
                v-if="currentItem.tag !== 'draggable'"
                :fieldName="currentItem.attrs.fieldName.__val__"
                v-model="modelValue.formConf.__rules"
              ></rules-input>

              <el-button
                type="primary"
                size="mini"
                @click="deleteItem(currentItem.__ID)"
                >Eliminar
              </el-button>
              <br />
              <br />
              <br />
            </template>
          </el-scrollbar>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { ref, defineComponent, toRaw, computed, inject, watch } from "vue";

import {
  eleRenderFormat,
  eleRenderSetFormat,
} from "@/form_builder/utils/utils.js";
import { findEle } from "@/form_builder/utils/func.js";
import RulesInput from "@/form_builder/components/form/RulesInput.vue";
import ElementRender from "@/form_builder/components/form/ElementRender.vue";

export default defineComponent({
  components: { ElementRender, RulesInput },
  name: "page-setting",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const deleteItem = inject("deleteItem");
    const currentTab = ref("form");

    const currentItem = computed(() => {
      let ele = findEle(props.modelValue.drawingList, props.modelValue.current);
      if (ele) {
        currentTab.value = "field";
        return ele;
      }
    });

    watch(props.modelValue.drawingList, function () {
      ctx.emit("update:modelValue", props.modelValue);
    });

    const updateEleSet = function (val, field) {
      console.log("update element inside : ", val, field);
      let ele = findEle(props.modelValue.drawingList, props.modelValue.current);

      if (field in ele) {
        ele[field]["__val__"] = val;
        return;
      }
      for (let k of ["formItem", "attrs"]) {
        if (ele[k] && field in ele[k]) {
          ele[k][field]["__val__"] = val;
          return;
        }
      }
    };

    const formConf = toRaw(props.modelValue.formConf.attrs);

    return {
      currentTab,
      eleRenderFormat,
      eleRenderSetFormat,
      currentItem,
      formConf,
      updateEleSet,
      deleteItem,
    };
  },
});
</script>
