<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">Nro</th>
              <th scope="col" class="text-center">Fecha Solicitud</th>
              <th scope="col" class="text-center">Evento</th>
              <th scope="col" class="text-center">Fecha Inicio</th>
              <th scope="col" class="text-center">Fecha Fin</th>
              <th scope="col" class="text-center">Tipo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ formatDate(item.init_date) }}</td>
              <td>{{ item.title }}</td>
              <td>{{ formatDate(item.init_date) }}</td>
              <td>{{ formatDate(item.end_date) }}</td>
              <td>Interno</td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, inject, computed } from "vue";
import axios from "@/includes/connection";
import { toast } from "vue3-toastify";

import Pagination from "@/pages/components/Pagination.vue";

let list = ref([]);

onMounted(() => {
  loadItems();
});

function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  }).format(date);
}
function loadItems() {
  axios
    .get("/event/published")
    .then((response) => {
      console.log("list users", response.data);
      if (response.data) {
        list.value = response.data;
      }
    })
    .catch(function (error) {
      console.log(error);
    });
}
async function changeStatus(eventCode) {
  let options = this.recive;
  if (eventCode.inbox > 1) {
    options = this.accion;
  }
  const { value: action } = await this.$swal.fire({
    title: "Acción a Realizar",
    input: "select",
    inputOptions: options,
    inputPlaceholder: "Seleccione Accion",
    showCancelButton: true,
    inputValidator: (value) => {
      return new Promise((resolve) => {
        resolve();
      });
    },
  });
  if (action) {
    const dataJson = {
      event_id: eventCode.id,
      step_id: 1,
      action_id: action,
      state: 1,
    };
    this.$axios
      .post("/flow-event", dataJson)
      .then((response) => {
        console.log("response", response);
        this.loadItems();
        this.$swal.fire({
          title: "Registro Exitoso!!!",
          icon: "success",
        });
      })
      .catch(function (error) {
        this.$swal.fire({
          title: "Registro fallido!!!",
          icon: "warning",
        });
      });
  }
}

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return list.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
