<template>
  <div class="card" v-show="state">
    <div class="card-body">
      <div class="email-app-sidebar left-bookmark">
        <ul class="nav main-menu contact-options" role="tablist">
          <li class="nav-item">
            <span class="main-title" style="text-transform: uppercase">
              Lista</span
            >
          </li>
          <ModeRegular
            :state="modeRegular"
            :levels="levels"
            :courses="courses"
            @chooseLevel="chooseLevel"
            @chooseCourse="chooseCourse"
            @chooseGrade="chooseGrade"
          />
          <ModeSpecial :state="modeSpecial" />
        </ul>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";
import ModeRegular from "./ModeRegular.vue";
import ModeSpecial from "./ModeSpecial.vue";

const emits = defineEmits(["chooseLevel", "chooseCourse", "chooseGrade"]);

const modeRegular = computed(() => {
  return props.institution.code_type == 1;
});

const modeSpecial = computed(() => {
  return props.institution.code_type == 4;
});

const props = defineProps({
  state: Boolean,
  institution: Object,
  levels: Array,
  courses: Array,
});

const chooseLevel = (level) => {
  emits("chooseLevel", level);
};

const chooseCourse = (course) => {
  emits("chooseCourse", course);
};

const chooseGrade = (grade) => {
  emits("chooseGrade", grade);
};
</script>
<style></style>
