<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <input
          v-model="searchQuery"
          placeholder="Buscar"
          class="form-control"
        />
        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Departamento</th>
              <th scope="col">Distrito</th>
              <th scope="col">Categoria</th>
              <th scope="col">RUDE</th>
              <th scope="col">Paterno</th>
              <th scope="col">Materno</th>
              <th scope="col">Nombre</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, key) in filteredItems" :key="item.id">
              <th>{{ key + 1 + (currentPage - 1) * itemsPerPage }}</th>
              <td>{{ item.departamento }}</td>
              <td>{{ item.distrito }}</td>
              <td>{{ item.category }}</td>
              <td>{{ item.codigo_rude }}</td>
              <td>{{ item.paterno }}</td>
              <td>{{ item.materno }}</td>
              <td>{{ item.nombre }}</td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="selectParticipant(item.id)"
                      :checked="checkStatus(item.id)"
                      :disabled="statusEnrollment"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        class="card"
        style="padding-bottom: 10px"
        :totalItems="students.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import axios from "@/includes/connection";

const searchQuery = ref("");

const filteredItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;

  let filter = list.value.slice(startIndex, endIndex);

  return filter.filter((item) => {
    return (
      item.departamento
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()) ||
      item.distrito.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.codigo_rude
        .toLowerCase()
        .includes(searchQuery.value.toLowerCase()) ||
      item.paterno.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.materno.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      item.nombre.toLowerCase().includes(searchQuery.value.toLowerCase())
    );
  });
});

const props = defineProps({
  students: Array,
  statusEnrollment: Boolean,
  participantSelected: Array,
});
const emit = defineEmits(["toggleParticipant"]);

const list = computed(() => {
  return props.students;
});

const participantSelected = computed(() => {
  return props.participantSelected;
});

async function selectParticipant(participant) {
  console.log("participant", participant);

  await axios
    .post("/enrollment-list/national", { participant })
    .then((response) => {
      emit("toggleParticipant", participant);
    })
    .catch(function (error) {
      console.log(error);
      emit("toggleParticipant", participant);
    });
}

// -- pagination section
let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  return students.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}

function checkStatus(code) {
  const out = participantSelected.value.find(
    (item) => item.participant_id == code
  );
  return out;
}
</script>
