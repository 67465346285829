<template>
  <Breadcrumbs title="Institución" />

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <div class="col-xl-12 col-md-12 box-col-12">
          <div class="email-right-aside bookmark-tabcontent contacts-tabs">
            <div class="card email-body radius-left">
              <div class="ps-0">
                <div class="tab-content">
                  <div
                    class="tab-pane fade"
                    v-bind:class="
                      activeclass === 'pills-personal-tab' ? 'active show' : ''
                    "
                    id="pills-personal"
                    role="tabpanel"
                    aria-labelledby="pills-personal-tab"
                  >
                    <personalContact
                      :students="listStudent"
                      :participantList="participantList"
                      :participantSelected="participantSelected"
                      :statusEnrollment="statusEnrollment"
                      @changeStatusEnrollment="changeStatus"
                      @getReport="generateReport"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-show="false">
    <div class="report" ref="reportEnrollment">
      <img src="@/assets/minedu.png" alt="minedu-logo" style="height: 60px" />
      <div class="text-center" style="padding-top: 20px">
        <h5>MINISTERIO DE EDUCACIÓN</h5>

        <!-- <h5 v-if="selectInstitution.institucioneducativa_tipo_id == 1">
          VICEMINISTERIO DE EDUCACIÓN REGULAR
        </h5>
        <h5 v-else>VICEMINISTERIO DE EDUCACIÓN ALTERNATIVA Y ESPECIAL</h5> -->
        <h5>
          REPORTE DE PARTICIPANTES CLASIFICADOS A LA 2DA. OLIMPIADA DE AJEDREZ
          DEL SISTEMA EDUCATIVO PLURINACIONAL - GESTIÓN 2024
        </h5>
      </div>
      <div style="padding-top: 10px">
        <table class="info" width="100%">
          <tr>
            <td width="50%">
              DEPARTAMENTO:
              <b style="text-transform: uppercase">{{ departamentName }}</b>
            </td>
            <td>GESTIÓN: <b>2024</b></td>
          </tr>
        </table>
      </div>
      <div style="padding-top: 15px">
        <table class="content" border="1" width="100%">
          <tr>
            <th>N°</th>
            <th>RUDEAL/RUDE/RUDEES</th>
            <th>CARNET</th>
            <th>NOMBRES Y APELLIDOS</th>
            <th>GENERO</th>
            <th>CATEGORÍA</th>
          </tr>
          <tr
            v-for="(participant, index) in participantEnrolled"
            key="participant.carnet"
            class="no-page-break"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ participant.rude }}</td>
            <td>{{ participant.carnet }}</td>
            <td>{{ participant.participante }}</td>
            <td>{{ participant.genero }}</td>
            <td>{{ participant.categoria }}</td>
          </tr>
        </table>
        <p style="text-align: justify; font-size: 10px">
          El presente documento, constituye una Declaración Jurada sobre los
          datos del estudiante/participante clasificados de la primera FASE.
        </p>
      </div>
      <div style="padding-top: 10px" class="no-page-break">
        <h6>Educación Primaria Comunitaria Vocacional</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td>Pri1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td>Pri2</td>
              <td align="left">1</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="padding-top: 10px" class="no-page-break">
        <h6>Educación Secundaria Comunitaria Productiva</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td>Sec1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td>Sec2</td>
              <td align="left">1</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="padding-top: 10px" class="no-page-break">
        <h6>Estudiantes con discapacidad visual y auditiva de CEE</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td>EspAud1</td>
              <td align="left">1</td>
            </tr>
            <tr>
              <td>EspVis2</td>
              <td align="left">1</td>
            </tr>
          </table>
        </div>
      </div>
      <div style="padding-top: 10px" class="no-page-break">
        <h6>Estudiantes de Centros de Educación Alternativa</h6>
        <div class="text-center">
          <table class="content" border="1" width="80%">
            <tr>
              <th width="20%">Categoría</th>
              <th></th>
            </tr>
            <tr>
              <td>EPJA DAMAS - VARONES (Mixto)</td>
              <td align="left">1</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="no-page-break">
        <div style="padding-top: 100px" class="no-page-break">
          <table width="100%">
            <tr>
              <td width="30%"></td>
              <td width="40%">
                <div class="text-center">
                  <p class="signature-line">
                    Sello y Firma del Director/a Departamental de Educación
                  </p>
                </div>
              </td>
              <td width="30%"></td>
            </tr>
          </table>
        </div>
        <div style="padding-top: 80px" class="no-page-break">
          <p style="font-style: italic; font-size: 10px; text-align: justify">
            <b>Nota:</b>*El registro de los nombres de las y los
            estudiantes/participantes clasificados debe realizarse sin tener
            sobrescritos.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import personalContact from "./personalContact.vue";
import { onMounted, ref, computed } from "vue";
import axios from "@/includes/connection";

import { toast } from "vue3-toastify";

onMounted(() => {
  if (localStorage.getItem("type") != 2) {
    toast.error("Perfil no valido!");
    return;
  }
  console.log("load one ... ");
  getStatusEnrollment();
  listparticipantsClasified();
  getParticipantSelected();
});

const listStudent = ref([]);

function listparticipantsClasified() {
  console.log("load two");
  axios
    .get("/institution/list-places/" + localStorage.getItem("username"))
    .then((response) => {
      console.log("out ", response.data);
      listStudent.value = response.data;
    })
    .catch(function (error) {
      toast.error("Falla al conectar con el server.");
    });
}

// --------------------------refactor

const activeclass = ref("pills-personal-tab");
const filtered = ref(false);

function active(item) {
  activeclass = item;
}

function changeStatus(status) {
  statusEnrollment.value = status;
  console.log("status", status);
}

const participantList = ref([]);

const statusEnrollment = ref();
function getStatusEnrollment() {
  axios
    .get(
      "/enrollment-list/enrolled/institution/" + localStorage.getItem("place")
    )
    .then((response) => {
      console.log("status_en", response.data);
      statusEnrollment.value = response.data.state;
    })
    .catch(function (error) {
      console.log(error);
    });
}

// -------------------------- selected students
const participantSelected = ref([]);
function getParticipantSelected() {
  axios
    .get("/enrollment-list/saved/distrital/" + localStorage.getItem("place"))
    .then((response) => {
      console.log("getParticipantSelected", response.data);
      participantSelected.value = response.data;

      participantList.value = participantSelected.value.map((el) => {
        return el.participant_id;
      });
      console.log("list", participantList.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}

//--- report section
import html2pdf from "html2pdf.js";
const reportEnrollment = ref(null);

const participantEnrolled = ref();

const departamentName = ref();
const distritName = ref();

const districtId = ref();
const departmentId = ref();

async function getEnrolledList() {
  const user = localStorage.getItem("username");
  await axios
    .get("/institution/get-user-depto/" + user)
    .then((response) => {
      console.log("data location", response.data);
      departmentId.value = response.data.codigo;
      departamentName.value = response.data.lugar;
    })
    .catch(function (error) {
      console.log(error);
    });

  await axios
    .get("/institution/list-student/enrolled-depto/" + departmentId.value)
    .then((response) => {
      participantEnrolled.value = response.data;
      console.log("participantEnrolled", participantEnrolled.value);
    })
    .catch(function (error) {
      console.log(error);
    });
}

const generateReport = async () => {
  console.log("generate report before");
  await getEnrolledList();
  console.log("generate report for", departmentId.value);
  html2pdf(reportEnrollment.value, {
    margin: 1,
    filename: departamentName.value + ".pdf",
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  }).from(() => {
    const pdf = document.querySelector(".html2pdf"); // Select the generated PDF element
    const pdfBlob = pdf2blob(pdf); // Convert the PDF element to a Blob
    const pdfUrl = URL.createObjectURL(pdfBlob); // Create a URL for the Blob
    const downloadLink = document.createElement("a"); // Create a download link element
    downloadLink.href = pdfUrl;
    downloadLink.download = "report.pdf"; // Set the filename for download
    downloadLink.click(); // Simulate a click event to trigger the download
  });
};

function pdf2blob(pdf) {
  const pdfData = new Blob([pdf.outerHTML], { type: "application/pdf" });
  return pdfData;
}
</script>
