<template>
  <div v-show="state" style="padding-top: 10px" class="col-12">
    <li v-for="level in levels" :key="level.code">
      <button
        class="btn btn-default col-12"
        type="button"
        @click="toggleCollapse(level.code)"
        style="margin-bottom: 5px"
      >
        {{ level.desc }}
      </button>
      <div v-if="level.code === showItem">
        <ul>
          <li
            v-for="course in courses"
            :key="course.code_course"
            :class="showGrade === course ? 'item-active' : ''"
          >
            <a @click="selectCourse(course)">
              <span class="title" style="padding-left: 10px; padding-top: 5px">
                {{ numberToOrdinal(course.code_grade) }} -
                {{ numberToAlphabet(course.code_parallel) }}</span
              >
            </a>
          </li>
        </ul>
      </div>
    </li>
  </div>
</template>
<script setup>
import { computed, ref, defineProps, defineEmits } from "vue";

const emit = defineEmits(["chooseLevel", "chooseCourse"]);

const showItem = ref();
const showGrade = ref();

const props = defineProps({
  levels: Array,
  state: Boolean,
  courses: Array,
});

const toggleCollapse = (index) => {
  showItem.value = index;
  emit("chooseLevel", index);
};

const numberToOrdinal = (number) => {
  const ordinals = {
    1: "Primero",
    2: "Segundo",
    3: "Tercero",
    4: "Cuarto",
    5: "Quinto",
    6: "Sexto",
    7: "Séptimo",
    8: "Octavo",
    9: "Noveno",
    10: "Décimo",
  };

  if (number <= 10) {
    return ordinals[number] || number.toString();
  }

  return number + "º";
};

const numberToAlphabet = (num) => {
  let result = "";
  while (num > 0) {
    num--;
    result = String.fromCharCode(65 + (num % 26)) + result;
    num = Math.floor(num / 26);
  }
  return result;
};

const selectCourse = (course) => {
  showGrade.value = course;
  emit("chooseCourse", course.code_course);
};
</script>
<style scoped>
.item-active {
  background: linear-gradient(135deg, #8acaf5, #c9c9e2);
  border-radius: 10px;
  padding-bottom: 3px;
  box-sizing: border-box;
}
</style>
