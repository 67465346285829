<template>
  <Breadcrumbs main="Parametricas" title="Ambientes" />
  <div class="container-fluid">
    <div class="row project-cards">
      <TopMenu />
      <Table />
    </div>
  </div>
</template>
<script>
import TopMenu from "./components/TopMenu.vue";
import Table from "./components/Table.vue";
export default {
  name: "place",
  components: {
    TopMenu,
    Table,
  },
};
</script>
