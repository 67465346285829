<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">RUDE</th>
              <th scope="col">Paterno</th>
              <th scope="col">Materno</th>
              <th scope="col">Nombre</th>
              <th scope="col" class="text-center">Genero</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list" :key="item.student.id">
              <th>{{ index + 1 }}</th>
              <th scope="row">{{ item.student.codigo_rude }}</th>
              <td>{{ item.student.paterno }}</td>
              <td>{{ item.student.materno }}</td>
              <td>{{ item.student.nombre }}</td>
              <td class="text-center">
                <template v-if="item.student.genero_tipo_id == 1">
                  <img :src="male" style="width: 40px" />
                </template>
                <template v-else>
                  <img :src="female" style="width: 40px" />
                </template>
              </td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="selectParticipant(item.student.id)"
                      :checked="checkStatus(item.student.id)"
                      :disabled="statusEnrollment"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";

const props = defineProps({
  students: Array,
  participantSelected: Array,
  statusEnrollment: Boolean,
});
const emit = defineEmits(["toggleParticipant"]);

const male = ref(require("@/assets/male.png"));
const female = ref(require("@/assets/female.png"));

const list = computed(() => {
  return props.students;
});

const participantSelected = computed(() => {
  return props.participantSelected;
});

function selectParticipant(participant) {
  emit("toggleParticipant", participant);
  console.log("select", participant);
}

function checkStatus(code) {
  const out = participantSelected.value.find(
    (item) => item.participant_id == code
  );
  return out;
}
</script>
