import helper from "@/form_builder/components/form/ui/helper.js";

export default {
  vue_tag: "input",
  tag: "el-input",
  name: "Tipo texto",
  __openRules: true,
  tagIcon: "input",
  __ID: "",
  defaultvalue: "",

  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    // labelWidth: helper.input_number("Ancho etiqueta", 100),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),
    placeholder: helper.input_text("Ejemplo entrada", "Ingrese valor"),
    type: helper.input_select(
      "tipo",
      [
        {
          key: "text",
          value: "texto",
        },
        {
          key: "textarea",
          value: "area de texto",
        },
        {
          key: "password",
          value: "contraseña",
        },
        {
          key: "number",
          value: "numero",
        },
      ],
      "text"
    ),
  },

  props: {},

  childrens: [],
  ctrlBtn: true,
  slots: {
    prefix: helper.input_text("box header", ""),
    suffix: helper.input_text("content at the end", ""),
    prepend: helper.input_text("box prepend", ""),
    append: helper.input_text("content after", ""),
  },

  rule: {
    required: helper.input_boolean("必填", false),
  },
};
