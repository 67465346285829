<template>
  <Breadcrumbs main="Olimpiadas" title="Ajedrez" />

  <div class="container-fluid">
    <div>
      <input class="form-control" type="number" v-model="codeSie" />
      <button class="btn btn-success" @click="clearInstitution">Limpiar</button>
    </div>
  </div>
</template>

<script setup>
import axios from "@/includes/connection";
import { toast } from "vue3-toastify";

import { ref } from "vue";
const codeSie = ref(0);

function clearInstitution() {
  axios
    .get("/institution/reset-enrollment/" + codeSie.value)
    .then((response) => {
      toast.success("Exito!");
    })
    .catch(function (error) {
      console.log(error);
      toast.error("Falla en la reversion!");
    });
}
</script>
