<template>
  <div class="col-sm-12">
    <div class="card">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="text-center">ID</th>
              <th scope="col" class="text-center">Categoría</th>
              <th scope="col" class="text-center">Puntuación ELO</th>
              <th scope="col" class="text-center">Especificación</th>
              <th scope="col" class="text-center">Sistema Educativo</th>
              <th scope="col" class="text-center">Nivel</th>
              <th scope="col" class="text-center">Criterios</th>
              <th scope="col" class="text-center">Fases</th>
              <th scope="col" class="text-center">Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in paginatedItems" :key="item.id">
              <th scope="row">{{ item.id }}</th>

              <td>{{ item.category }}</td>
              <td>{{ item.score }}</td>
              <td>{{ item.detail }}</td>
              <td>{{ item.system.descripcion }}</td>
              <td>{{ item.level.nivel }}</td>
              <td class="text-center">
                <router-link
                  class="icon-button"
                  :to="`/manager/criteria/${item.id}`"
                >
                  <button class="btn btn-secondary btn-sm">
                    {{ item.rules.length }}
                  </button>
                </router-link>
              </td>

              <td class="text-center">
                <router-link
                  class="icon-button"
                  :to="`/manager/phase/${item.id}`"
                >
                  <button class="btn btn-primary btn-sm">
                    {{ item.phase_category.length }}
                  </button>
                </router-link>
              </td>
              <td class="text-center">
                <div class="media-body icon-state switch-outline">
                  <label class="switch">
                    <input
                      type="checkbox"
                      @change="changeState(item.id)"
                      :checked="item.state"
                    /><span class="switch-state bg-primary"></span>
                  </label>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        :totalItems="list.length"
        :itemsPerPage="itemsPerPage"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import Pagination from "@/pages/components/Pagination.vue";
import axios from "@/includes/connection";
const props = defineProps({ list: Array });
const list = computed(() => {
  return props.list;
});

function changeState(code) {
  axios
    .patch("/category/" + code)
    .then((response) => {
      console.log("update state", response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
}

let currentPage = ref(1);
let itemsPerPage = ref(10);

let paginatedItems = computed(() => {
  const startIndex = (currentPage.value - 1) * itemsPerPage.value;
  const endIndex = startIndex + itemsPerPage.value;
  console.log("load pagination", list.value);
  return list.value.slice(startIndex, endIndex);
});

function handlePageChange(page) {
  currentPage.value = page;
}
</script>
