import helper from "@/form_builder/components/form/ui/helper.js";

const elOption = {
  vue_tag: "select",
  tag: "el-option",
  name: "eloption",
  __openRules: false,
  tagIcon: "input",
  ctrlBtn: true,
  attrs: {
    fieldName: helper.input_text("Nombre campo", "Nombre campo"),
  },
  slots: {},
  props: {},
  childrens: [],
};

export default {
  tag: "el-select",
  name: "Tipo Lista",
  __openRules: false,
  tagIcon: "select",
  ctrlBtn: true,
  defaultvalue: "",

  __opt__: helper.input_opt("Opciones", "el-option"),

  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),
  },
  slots: {},
  props: {},
  childrens: [],
};
