<template>
  <div class="card email-body radius-left">
    <div class="ps-0">
      <div class="tab-content">
        <div
          class="tab-pane active show"
          id="pills-personal"
          role="tabpanel"
          aria-labelledby="pills-personal-tab"
        >
          <div class="card mb-0">
            <div class="card-header container">
              <div class="section"><h5>Estudiantes - Grupos</h5></div>

              <div class="text-center info-text section">
                <button
                  class="btn btn-primary"
                  @click="showModal"
                  :disabled="state"
                >
                  Agregar Grupo
                </button>
              </div>
              <div class="section" style="text-align: right">
                <button
                  class="btn btn-default"
                  @click="consolidar"
                  :disabled="state"
                >
                  Consolidar
                </button>
              </div>
            </div>
            <div class="card-body">
              <StudentList :students="students" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalGroup
    ref="thisModal"
    :student="student"
    :enrolled="enrolled"
    :institution="institution"
    @close-mdl="closeModal"
  />
</template>

<script setup>
import { defineProps, ref, defineEmits, inject } from "vue";
import { toast } from "vue3-toastify";
import StudentList from "./StudentList.vue";

import axios from "@/includes/connection";

const emit = defineEmits(["refresh"]);

const swal = inject("$swal");

const props = defineProps({
  students: Array,
  institution: Object,
  state: Boolean,
});

// -- modal section
import { Modal } from "bootstrap";
import ModalGroup from "./ModalGroup.vue";
import Institution from "@/pages/institution/Institution.vue";
let thisModal = ref(null);
let modal = null;

const showModal = () => {
  const modalElement = thisModal.value.$el;
  modal = new Modal(modalElement);
  modal.show();
};

const closeModal = () => {
  console.log("close-modal", modal);
  // const modalElement = thisModal.value.$el;
  // const modal = new Modal(modalElement);
  // modal.hide();
  if (modal) {
    modal.hide();
    emit("refresh");
  }
};

const consolidar = () => {
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Una vez consolidado se concluira el proceso",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        axios
          .get("/structure/close-enrollment/" + props.institution.id)
          .then((response) => {
            toast.success("Pre-Inscripción exitosa!!!");
            emit("refresh");
          })
          .catch(function (error) {
            toast.error("Pre-Inscripción fallida!!!");
          });
      }
    });
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.section {
  flex: 1;
  box-sizing: border-box;
  color: white;
}
</style>
