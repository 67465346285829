<template>
  <li class="nav-item">
    <div style="padding: 30px 20px">
      <label for="">Código SIE</label>
      <input type="text" class="form-control" v-model="rueValue" />
      <button class="btn btn-primary col-12" @click="findRue">Buscar</button>
      <div style="padding-top: 10px" v-if="enableReports">
        <button class="btn btn-success col-12" @click="downloadReport">
          Reporte Inscritos
        </button>
      </div>
    </div>
  </li>
</template>

<script setup>
import { defineProps, computed, ref, defineEmits } from "vue";
import axios from "@/includes/connection";
import { toast } from "vue3-toastify";

const emit = defineEmits(["selectSchool", "getReport"]);

const enableReports = computed(() => {
  const currentDate = new Date();
  const dateToCompare = new Date("2024-07-09");
  if (dateToCompare > currentDate) {
    return false;
  }
  return true;
});

const rueValue = ref("");
function findRue() {
  console.log("finded", rueValue.value);
  axios
    .get(
      "/institution/list/rue-by-user/" +
        localStorage.getItem("username") +
        "/" +
        rueValue.value
    )
    .then((response) => {
      if (response.data) {
        emit("selectSchool", response.data[0].rue);
      }
    })
    .catch(function (error) {
      toast.error("Sie no valido o no asignado a su perfil.");
    });
}

const list = computed(() => {
  return props.list;
});

function downloadReport() {
  emit("getReport");
}
</script>
