<template>
  <Breadcrumbs main="Torneo Ajedrez" title="Reporte" />
  <div style="padding: 10px 20px">
    <select v-model="phaseId" class="form-control" @change="changePhase">
      <option value="1">Fase Local</option>
      <option value="2">Fase Distrital</option>
    </select>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5>Estado Inscripción Nacional</h5>
              <div class="card-header-right-icon"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <BoxReportNational :series="national" />
              </div>
              <div class="col-6">
                <BoxReportSpecific :series="nationalDetail" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5>Educación Regular</h5>
              <div class="card-header-right-icon"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-3">
                <h6>Pri1</h6>
                <BoxReport :series="catPri1" />
              </div>
              <div class="col-3">
                <h6>Pri2</h6>
                <BoxReport :series="catPri2" />
              </div>
              <div class="col-3">
                <h6>Sec1</h6>
                <BoxReport :series="catSec1" />
              </div>
              <div class="col-3">
                <h6>Sec2</h6>
                <BoxReport :series="catSec2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5>Educación Alternativa</h5>
              <div class="card-header-right-icon"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <h6>EPJA DAMAS-VARONES (Mixto)</h6>
                <BoxReport :series="catAlt1" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="header-top">
              <h5>Educación Especial</h5>
              <div class="card-header-right-icon"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-6">
                <h6>EspAud1</h6>
                <BoxReport :series="catEsp1" />
              </div>
              <div class="col-6">
                <h6>EspVis2</h6>
                <BoxReport :series="catEsp2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import BoxReport from "./BoxReport.vue";
import BoxReportNational from "./BoxReportNational.vue";
import BoxReportSpecific from "./BoxReportSpecific.vue";

import axios from "@/includes/connection";

const phaseId = ref(1);

const pri1 = ref(3);
const pri2 = ref(4);
const sec1 = ref(5);
const sec2 = ref(6);
const alt1 = ref(7);
const esp1 = ref(8);
const esp2 = ref(10);

function changePhase() {
  console.log("load here", phaseId.value);
  getReport(phaseId.value);
  getReportSpecific(phaseId.value);
  getReportCategory(catPri1, pri1.value, phaseId.value);
  getReportCategory(catPri2, pri2.value, phaseId.value);
  getReportCategory(catSec1, sec1.value, phaseId.value);
  getReportCategory(catSec2, sec2.value, phaseId.value);
  getReportCategory(catAlt1, alt1.value, phaseId.value);
  getReportCategory(catEsp1, esp1.value, phaseId.value);
  getReportCategory(catEsp2, esp2.value, phaseId.value);
}

const national = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

onMounted(() => {
  getReport(phaseId.value);
  getReportSpecific(phaseId.value);
  getReportCategory(catPri1, pri1.value, phaseId.value);
  getReportCategory(catPri2, pri2.value, phaseId.value);
  getReportCategory(catSec1, sec1.value, phaseId.value);
  getReportCategory(catSec2, sec2.value, phaseId.value);
  getReportCategory(catAlt1, alt1.value, phaseId.value);
  getReportCategory(catEsp1, esp1.value, phaseId.value);
  getReportCategory(catEsp2, esp2.value, phaseId.value);
});

function getReport(phaseId) {
  axios
    .get("/enrollment-list/report/all/" + phaseId)
    .then((response) => {
      national.value[0].data = [response.data[0].enrolled];
    })
    .catch(function (error) {
      console.log(error);
    });
}

const nationalDetail = ref([
  {
    data: [],
    name: "Inscritos",
    colors: ["#33b2df", "#546E7A"],
  },
]);

function getReportSpecific(category) {
  axios
    .get("/enrollment-list/report/departments/" + category)
    .then((response) => {
      nationalDetail.value[0].data = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

const catPri1 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catPri2 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catSec1 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catSec2 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catAlt1 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catEsp1 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

const catEsp2 = ref([
  {
    data: [],
    name: "Inscritos",
  },
]);

function getReportCategory(model, category, phaseId) {
  axios
    .get("/enrollment-list/report/category/" + category + "/" + phaseId)
    .then((response) => {
      model.value[0].data = response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}
</script>
<style></style>
