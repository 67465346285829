<template>
  <Breadcrumbs title="Usuarios" main="Users" />

  <div class="container-fluid">
    <div class="user-profile">
      <div class="row">
        <projects />
      </div>
    </div>
  </div>
</template>

<script>
import projects from "./projects.vue";
export default {
  components: {
    projects,
  },
};
</script>
