<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <!-- <img class="b-r-10" src="@/assets/images/dashboard/profile.png" alt="" /> -->
      <div class="media-body">
        <span>{{ name }}</span>
        <p class="mb-0 font-roboto">
          Opciones <i class="middle fa fa-angle-down"></i>
        </p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li>
        <a @click="logout"><span>Salir</span></a>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      name: "",
    };
  },
  created() {
    if (localStorage.getItem("User")) {
      const user = JSON.parse(localStorage.getItem("User"));
      this.name = user.email;
    }
  },
  methods: {
    logout: function () {
      // this.$router.replace("/auth/login");
      window.location.href = "/auth/login";
      localStorage.removeItem("User");
    },
  },
};

// data: {
//   name: 'User'
// },
</script>
