import helper from "@/form_builder/components/form/ui/helper.js";

export default {
  vue_tag: "input",
  tag: "el-time-select",
  name: "Tipo tiempo",
  __openRules: false,
  tagIcon: "time-range",
  defaultvalue: "",
  ctrlBtn: true,
  formItem: {
    showLabel: helper.input_boolean("Mostrar etiqueta", true),
    // labelWidth: helper.input_number("Ancho etiqueta", 100),
    label: helper.input_text("Etiqueta", "Campo"),
  },
  attrs: {
    fieldName: helper.input_text("Nombre campo", "nombre campo"),
    // disabled: helper.input_boolean("禁用", false),
    // editable: helper.input_boolean("文本框可输入", true),
    // clearable: helper.input_boolean("是否显示清除按钮", true),
    // size: helper.input_radio(
    //   "输入框尺寸",
    //   [
    //     {
    //       key: "medium",
    //       value: "medium",
    //     },
    //     {
    //       key: "small",
    //       value: "small",
    //     },
    //     {
    //       key: "mini",
    //       value: "mini",
    //     },
    //   ],
    //   ""
    // ),
  },
  slots: {},
  props: {},
  childrens: [],
};
