<template>
  <button class="btn btn-primary" type="button" @click="createModal.show()">
    <i class="fa fa-plus"> </i>
  </button>

  <div
    class="modal fade"
    ref="eventModal"
    id="exampleModalCenter"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenter"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <vee-form :validation-schema="placeSchema" @submit="registerPlace">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Crear Fase</h5>
            <button
              class="btn-close"
              type="button"
              @click="createModal.hide()"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label>Fase</label>
                  <vee-field class="form-control" name="detail" type="text" />
                  <ErrorMessage class="text-danger" name="detail" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label>Order</label>
                  <vee-field class="form-control" name="order" type="number" />
                  <ErrorMessage class="text-danger" name="order" />
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              type="button"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button class="btn btn-primary" type="submit">Registrar</button>
          </div>
        </div>
      </vee-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject, watch, defineProps } from "vue";
import axios from "@/includes/connection";
import { Modal } from "bootstrap";

const swal = inject("$swal");
const createEventModal = ref(null);
const emits = defineEmits(["reload-list"]);

const props = defineProps({ phaseId: Number });
console.log("props ", props.phaseId);

function registerPlace(values, { resetForm }) {
  values.tournament_id = props.phaseId;
  swal
    .fire({
      title: "¿Esta seguro?",
      text: "Se registrara el nuevo registro?.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Registrar",
      cancelButtonText: "Cancelar",
    })
    .then((result) => {
      if (result.isConfirmed) {
        console.log("values for place >> ", values);
        axios
          .post("/phases", values)
          .then((response) => {
            console.log("response", response);
            resetForm();
            swal
              .fire({
                title: "Registro Exitoso!!!",
                text: "Se registro de manera exitosa",
                icon: "success",
              })
              .then((result) => {
                if (result.isConfirmed) {
                  emits("reload-list");
                  createModal.hide();
                }
              });
          })
          .catch(function (error) {
            console.log(error);
            swal.fire({
              title: "Registro fallido!!!",
              text: "No se pudo registrar",
              icon: "warning",
            });
          });
      }
    });
}

const eventModal = ref(null);
let createModal = null;
onMounted(() => {
  createModal = new Modal(eventModal.value);
});

const placeSchema = {
  detail: "required",
  order: "required",
};
</script>
