<template>
  <el-tabs type="border-card" v-model="current">
    <el-tab-pane label="Valores" name="static">
      <el-form size="small">
        <el-form-item>
          <el-row>
            <template
              v-for="(item, index) in staticData"
              :key="'index' + index"
            >
              <el-col :span="22">
                <el-form-item
                  label="Opción"
                  :rules="{
                    required: true,
                    message: 'Valor requerido',
                    trigger: 'blur',
                  }"
                >
                  <el-input v-model="item.key"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1" :offset="1" @click="delItem(index)">
                <i class="el-icon-delete"></i>
              </el-col>
            </template>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button size="mini" @click.prevent="addItem">Agregar</el-button>
        </el-form-item>
      </el-form>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { ref, defineComponent, watch, reactive } from "vue";
import { deepClone } from "@/form_builder/utils/func.js";
export default defineComponent({
  name: "OptionInput",
  emits: ["update:modelValue"],
  props: ["modelValue", "defaultvalue"],

  setup(props, ctx) {
    let modelValue = props.modelValue || {};

    const current = ref(props.modelValue.type);
    const staticData = reactive(deepClone(props.modelValue.staticData));
    const dynamicData = reactive(deepClone(props.modelValue.dynamicData));

    const addItem = function () {
      staticData.push({ key: "", value: "" });
    };

    const delItem = function (index) {
      staticData.splice(index, 1);
    };

    watch([dynamicData, staticData, current], () => {
      console.log(current.value);
      ctx.emit("update:modelValue", {
        type: current.value,
        tag: props.modelValue.tag,
        staticData,
        dynamicData,
      });
    });

    return { staticData, addItem, delItem, dynamicData, current };
  },
});
</script>

<style></style>
