<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script setup>
import { ref, defineProps, computed, onMounted } from "vue";
import axios from "@/includes/connection";

const props = defineProps({
  series: Array,
});

const chartOptions = ref({
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: "end",
      horizontal: true,
      distributed: true,
    },
  },
  fill: {
    colors: [
      "#33b2df",
      "#546E7A",
      "#d4526e",
      "#13d8aa",
      "#A5978B",
      "#2b908f",
      "#f9a3a4",
      "#90ee7e",
      "#f48024",
      "#69d2e7",
    ],
  },
  colors: [
    "#33b2df",
    "#546E7A",
    "#d4526e",
    "#13d8aa",
    "#A5978B",
    "#2b908f",
    "#f9a3a4",
    "#90ee7e",
    "#f48024",
    "#69d2e7",
  ],

  dataLabels: {
    enabled: true,
    style: {
      colors: ["#fff"],
    },
  },
  legend: {
    show: false,
  },
  xaxis: {
    categories: [
      "La Paz",
      "Cochabamba",
      "Santa Cruz",
      "Tarija",
      "Potosi",
      "Chuquisaca",
      "Beni",
      "Oruro",
      "Pando",
    ],
  },
});
</script>
